import React, { useState, useEffect } from 'react';
import { 
  FiUploadCloud, 
  FiX, 
  FiMinimize2, 
  FiMaximize2, 
  FiCheck, 
  FiAlertTriangle, 
  FiClock, 
  FiFile, 
  FiHash, 
  FiTag,
  FiTrash2
} from 'react-icons/fi';
import './UploadStatusWindow.css';

// Helper functions for upload tracking (same as in DocumentUpload.js)
const getOngoingUploads = () => {
  try {
    return JSON.parse(localStorage.getItem('ongoingUploads') || '[]');
  } catch (e) {
    console.error('Error parsing ongoing uploads:', e);
    return [];
  }
};

// Function to update localStorage
const updateOngoingUploads = (uploads) => {
  try {
    localStorage.setItem('ongoingUploads', JSON.stringify(uploads));
  } catch (e) {
    console.error('Error updating ongoing uploads:', e);
  }
};

// Check if file modal is open
const isFileModalOpen = () => {
  try {
    // Check both implementations
    const modalOpenInApp = JSON.parse(localStorage.getItem('fileModalOpen') || 'false');
    const modalOpenInComponents = JSON.parse(localStorage.getItem('fileModalOpen') || 'false');
    
    // If either modal is open, return true
    return modalOpenInApp || modalOpenInComponents;
  } catch (e) {
    console.error('Error checking if file modal is open:', e);
    return false;
  }
};

const UploadStatusWindow = () => {
  const [uploads, setUploads] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [activeUploads, setActiveUploads] = useState(0);
  const [completedUploads, setCompletedUploads] = useState(0);
  const [errorUploads, setErrorUploads] = useState(0);
  const [newNotifications, setNewNotifications] = useState(0);
  const [lastCheckedTimestamp, setLastCheckedTimestamp] = useState(Date.now());
  const [fileModalOpen, setFileModalOpen] = useState(false);

  // Load uploads from localStorage and set up interval to check for updates
  useEffect(() => {
    const loadUploads = () => {
      const currentUploads = getOngoingUploads();
      setUploads(currentUploads);
      
      // Check if file modal is open
      const modalOpen = isFileModalOpen();
      setFileModalOpen(modalOpen);
      
      // Count uploads by status
      const active = currentUploads.filter(upload => upload.status === 'processing').length;
      const completed = currentUploads.filter(upload => upload.status === 'completed').length;
      const errors = currentUploads.filter(upload => upload.status === 'error').length;
      
      setActiveUploads(active);
      setCompletedUploads(completed);
      setErrorUploads(errors);
      
      // Auto-show window if there are active uploads
      if (active > 0 && isHidden) {
        setIsHidden(false);
      }
      
      // Check for new notifications (completed or failed uploads since last check)
      if (isMinimized) {
        const newCompletedOrFailed = currentUploads.filter(
          upload => (upload.status === 'completed' || upload.status === 'error') && 
                   upload.lastUpdated > lastCheckedTimestamp
        ).length;
        
        if (newCompletedOrFailed > 0) {
          setNewNotifications(prev => prev + newCompletedOrFailed);
        }
      }
    };
    
    // Initial load
    loadUploads();
    
    // Set up interval to check for updates
    const interval = setInterval(loadUploads, 3000);
    
    return () => clearInterval(interval);
  }, [isHidden, isMinimized, lastCheckedTimestamp]);

  // Reset notifications when window is maximized
  useEffect(() => {
    if (!isMinimized) {
      setNewNotifications(0);
      setLastCheckedTimestamp(Date.now());
    }
  }, [isMinimized]);

  // Format timestamp to relative time (e.g., "2 minutes ago")
  const formatRelativeTime = (timestamp) => {
    if (!timestamp) return 'Unknown time';
    
    const now = Date.now();
    const diff = now - timestamp;
    
    // Convert to seconds
    const seconds = Math.floor(diff / 1000);
    
    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
    
    // Convert to minutes
    const minutes = Math.floor(seconds / 60);
    
    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    }
    
    // Convert to hours
    const hours = Math.floor(minutes / 60);
    
    if (hours < 24) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    }
    
    // Convert to days
    const days = Math.floor(hours / 24);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  };

  // Format file size
  const formatFileSize = (bytes) => {
    if (!bytes || bytes === 0) return '0 Bytes';
    
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  // Get status icon based on upload status
  const getStatusIcon = (status) => {
    switch (status) {
      case 'processing':
        return <FiClock />;
      case 'completed':
        return <FiCheck />;
      case 'error':
        return <FiAlertTriangle />;
      default:
        return <FiClock />;
    }
  };

  // Get status text based on upload status
  const getStatusText = (status) => {
    switch (status) {
      case 'processing':
        return 'Processing';
      case 'completed':
        return 'Completed';
      case 'error':
        return 'Failed';
      default:
        return 'Unknown';
    }
  };

  // Toggle minimize/maximize
  const toggleMinimize = () => {
    if (isMinimized) {
      // When maximizing, reset notifications
      setNewNotifications(0);
      setLastCheckedTimestamp(Date.now());
    }
    setIsMinimized(!isMinimized);
  };

  // Close the window
  const closeWindow = () => {
    setIsHidden(true);
  };

  // Clear completed uploads
  const clearCompletedUploads = () => {
    const updatedUploads = uploads.filter(upload => upload.status === 'processing');
    updateOngoingUploads(updatedUploads);
    setUploads(updatedUploads);
  };

  // Debug function to add a test upload (only in development)
  const addTestUpload = () => {
    if (process.env.NODE_ENV !== 'development') return;
    
    const testUpload = {
      id: `test_${Date.now()}`,
      fileName: `Test Document ${Math.floor(Math.random() * 1000)}`,
      fileSize: Math.floor(Math.random() * 10000000),
      fileType: 'application/pdf',
      channels: ['general', 'random', 'test-channel'],
      tags: ['test', 'debug'],
      serverId: 'test-server',
      startTime: Date.now(),
      status: 'processing'
    };
    
    const currentUploads = getOngoingUploads();
    updateOngoingUploads([...currentUploads, testUpload]);
    setUploads([...uploads, testUpload]);
  };

  // Calculate progress for processing uploads
  const calculateProgress = (upload) => {
    if (!upload.startTime) return 0;
    
    const now = Date.now();
    const elapsed = now - upload.startTime;
    
    // Assume processing takes about 2 minutes max
    const estimatedProcessingTime = 2 * 60 * 1000;
    const progress = Math.min(95, (elapsed / estimatedProcessingTime) * 100);
    
    return progress;
  };

  // If there are no uploads or the window is hidden or file modal is open, don't render anything
  if (uploads.length === 0 || isHidden || fileModalOpen) {
    return null;
  }

  return (
    <div className={`upload-status-window ${isMinimized ? 'minimized' : ''} ${isHidden ? 'hidden' : ''}`}>
      <div className="upload-status-header" onClick={toggleMinimize}>
        <h3>
          <FiUploadCloud />
          Document Uploads
          {activeUploads > 0 && (
            <span className="badge">{activeUploads} active</span>
          )}
          {isMinimized && newNotifications > 0 && (
            <span className="notification-badge">{newNotifications}</span>
          )}
        </h3>
        <div className="controls" onClick={(e) => e.stopPropagation()}>
          {process.env.NODE_ENV === 'development' && (
            <button 
              onClick={addTestUpload} 
              title="Add test upload (dev only)"
              className="debug-button"
            >
              Test
            </button>
          )}
          {completedUploads > 0 && (
            <button 
              onClick={clearCompletedUploads} 
              title="Clear completed uploads"
            >
              <FiTrash2 />
            </button>
          )}
          <button onClick={toggleMinimize}>
            {isMinimized ? <FiMaximize2 /> : <FiMinimize2 />}
          </button>
          <button onClick={closeWindow}>
            <FiX />
          </button>
        </div>
      </div>
      
      {!isMinimized && (
        <div className="upload-status-content">
          {uploads.length === 0 ? (
            <div className="upload-status-empty">
              <FiUploadCloud size={32} />
              <p>No recent uploads</p>
            </div>
          ) : (
            <>
              {/* Summary stats */}
              {(activeUploads > 0 || completedUploads > 0 || errorUploads > 0) && (
                <div className="upload-status-summary">
                  {activeUploads > 0 && (
                    <div className="upload-status-stat processing">
                      <FiClock /> {activeUploads} Processing
                    </div>
                  )}
                  {completedUploads > 0 && (
                    <div className="upload-status-stat completed">
                      <FiCheck /> {completedUploads} Completed
                    </div>
                  )}
                  {errorUploads > 0 && (
                    <div className="upload-status-stat error">
                      <FiAlertTriangle /> {errorUploads} Failed
                    </div>
                  )}
                </div>
              )}
              
              {/* Upload items */}
              {uploads.map((upload) => (
                <div key={upload.id} className={`upload-item ${upload.status}`}>
                  <div className="upload-item-header">
                    <div className={`upload-item-status ${upload.status}`}>
                      {getStatusIcon(upload.status)} {getStatusText(upload.status)}
                    </div>
                  </div>
                  
                  <div className="upload-item-details">
                    <div className="upload-item-name">
                      {upload.fileName || 'Unnamed document'}
                    </div>
                    
                    <div className="upload-item-meta">
                      <span>
                        <FiFile size={12} />
                        {formatFileSize(upload.fileSize)}
                      </span>
                      <span>
                        {upload.fileType || 'Unknown type'}
                      </span>
                    </div>
                    
                    {upload.status === 'error' && upload.error && (
                      <div className="upload-item-error">
                        {upload.error}
                      </div>
                    )}
                    
                    {upload.status === 'processing' && (
                      <div className="upload-item-progress">
                        <div 
                          className="upload-item-progress-bar" 
                          style={{ width: `${calculateProgress(upload)}%` }}
                        ></div>
                      </div>
                    )}
                    
                    <div className="upload-item-time">
                      {upload.status === 'processing' 
                        ? `Started ${formatRelativeTime(upload.startTime)}`
                        : `${upload.status === 'completed' ? 'Completed' : 'Failed'} ${formatRelativeTime(upload.lastUpdated)}`
                      }
                    </div>
                    
                    {upload.channels && upload.channels.length > 0 && (
                      <div className="upload-item-channels">
                        {upload.channels.slice(0, 3).map((channel, index) => (
                          <div key={index} className="upload-item-channel">
                            <FiHash size={10} />
                            {channel}
                          </div>
                        ))}
                        {upload.channels.length > 3 && (
                          <div className="upload-item-channel">
                            +{upload.channels.length - 3} more
                          </div>
                        )}
                      </div>
                    )}
                    
                    {upload.tags && upload.tags.length > 0 && (
                      <div className="upload-item-tags">
                        {upload.tags.slice(0, 3).map((tag, index) => (
                          <div key={index} className="upload-item-tag">
                            <FiTag size={10} />
                            {tag}
                          </div>
                        ))}
                        {upload.tags.length > 3 && (
                          <div className="upload-item-tag">
                            +{upload.tags.length - 3} more
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadStatusWindow; 