import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { useServer } from '../contexts/ServerContext.js';
import ServerSelectionModal from './ServerSelectionModal.js';
import TabHeader from './TabHeader.js';
import { handleApiCall } from '../utils/api.js';
import WordCloud from './WordCloud.js';
import SubscriptionSelector from './SubscriptionSelector.js';

// Helper function to format relative time
const formatRelativeTime = (timestamp) => {
  const now = new Date();
  const date = new Date(timestamp);
  const diffInSeconds = Math.floor((now - date) / 1000);

  if (diffInSeconds < 60) {
    return 'just now';
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
  }
};

function Dashboard() {
  const { 
    isOnboarded, 
    availableServers,
    completeOnboarding,
    activeAccount,
    accounts
  } = useServer();

  const user = JSON.parse(localStorage.getItem('user'));
  const authToken = localStorage.getItem('authToken');

  const [stats, setStats] = useState({
    totalMessages: '0',
    activeUsers: '0',
    reactionRate: '0%',
    questionsAsked: '0'
  });
  
  const [recentActivity, setRecentActivity] = useState([]);
  const [quickInsights, setQuickInsights] = useState([]);
  const [topics, setTopics] = useState([]);
  const [dateRange, setDateRange] = useState('24h'); // '24h', '7d', '30d', 'all'
  const [isLoading, setIsLoading] = useState(true);
  const [showSubscriptionSelector, setShowSubscriptionSelector] = useState(false);

  // Function to fetch server stats
  const fetchServerStats = async () => {
    if (!activeAccount) {
      console.log('No active account, skipping fetch');
      return;
    }

    const account = accounts.find(acc => acc.id === activeAccount);
    if (!account?.discord_server_id) {
      console.log('No discord_server_id found for account:', account);
      return;
    }

    setIsLoading(true);
    try {
      const { start, end } = getDateRange(dateRange);
      const params = new URLSearchParams({
        discord_server_id: account.discord_server_id,
        end_date: end
      });

      if (start) {
        params.append('start_date', start);
      }

      const data = await handleApiCall(
        `https://xqx2-ksev-bf5k.n7.xano.io/api:HZtICWLN/server/general?${params.toString()}`
      );

      if (data) {
        const totalMessages = data.quickInsights?.reduce((sum, insight) => sum + (insight.messages_count || 0), 0) || 0;

        setStats({
          totalMessages: totalMessages.toString(),
          activeUsers: data.activeUsers?.toString() || '0',
          reactionRate: data.server?.reactionrate_of_servers ? `${data.server.reactionrate_of_servers}%` : '0%',
          questionsAsked: data.asked?.toString() || '0'
        });

        const mappedActivity = data.activity?.items?.map(item => ({
          ...item,
          timestamp: formatRelativeTime(item.created_at),
          user: {
            ...item.user,
            discord_name: item.user?.discord_name ? 
              (item.user.discord_name.length > 20 && !item.user.discord_name.includes(' ') ? 
                item.user.discord_name.substring(0, 8) + '...' : 
                item.user.discord_name) : 
              'Unknown user'
          }
        })) || [];

        setRecentActivity(mappedActivity);
        setQuickInsights(data.quickInsights || []);
        setTopics(data.topics || []);
      }
    } catch (error) {
      console.error('Error fetching server stats:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkSubscription = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem('user'));
      const authToken = localStorage.getItem('authToken');

      if (!userData || !authToken) {
        setShowSubscriptionSelector(true);
        return;
      }

      const response = await fetch(
        `https://xqx2-ksev-bf5k.n7.xano.io/api:ka2b0RTY/user_subscriptions/lookup/${userData.id}`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          setShowSubscriptionSelector(true);
        }
        return;
      }

      const subscriptionData = await response.json();
      if (!subscriptionData || !subscriptionData.active) {
        setShowSubscriptionSelector(true);
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
      setShowSubscriptionSelector(true);
    }
  };

  // Fetch server stats when needed
  useEffect(() => {
    fetchServerStats();
  }, [activeAccount, accounts, dateRange]);

  // Check subscription status on mount
  useEffect(() => {
    checkSubscription();
  }, []);

  const handleDateRangeChange = (newRange) => {
    setDateRange(newRange);
  };

  const handleSubscriptionComplete = async () => {
    // Hide the subscription selector
    setShowSubscriptionSelector(false);
    
    // Re-check subscription status
    if (user?.id && authToken) {
      try {
        await checkSubscription();
      } catch (error) {
        console.error('Error checking subscription after completion:', error);
      }
    }
  };

  const getDateRange = (range) => {
    // Get current date and set to midnight of next day for end date
    const today = new Date();
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0, 0);
    
    // Adjust for timezone offset
    const tzOffset = endDate.getTimezoneOffset() * 60000; // convert to milliseconds
    const endDateUTC = new Date(endDate.getTime() - tzOffset);

    // For 'all' duration, return null start date
    if (range === 'all') {
      return { start: null, end: endDateUTC.toISOString() };
    }
    
    // Calculate start date based on range
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
    
    switch (range) {
      case 'today':
        // Start date is already set to midnight of current day
        break;
      case '24h':
        // Go back exactly 24 hours from end date
        startDate.setDate(startDate.getDate() - 1);
        break;
      case '7d':
        // Go back 7 days from current day
        startDate.setDate(startDate.getDate() - 7);
        break;
      case '30d':
        // Go back 30 days from current day
        startDate.setDate(startDate.getDate() - 30);
        break;
      default:
        // Default to today
        break;
    }

    // Adjust start date for timezone offset
    const startDateUTC = new Date(startDate.getTime() - tzOffset);
    
    return { 
      start: startDateUTC.toISOString(),
      end: endDateUTC.toISOString()
    };
  };

  const getTimeRangeLabel = (range) => {
    switch (range) {
      case 'today':
        return 'Today';
      case '24h':
        return 'In last 24 hours';
      case '7d':
        return 'In last 7 days';
      case '30d':
        return 'In last 30 days';
      case 'all':
        return 'All time';
      default:
        return 'In last 24 hours';
    }
  };

  const LoadingSpinner = () => (
    <div className="app-spinner"></div>
  );

  const LoadingCard = () => (
    <div className="loading-card">
      <LoadingSpinner />
    </div>
  );

  if (!isOnboarded) {
    return (
      <ServerSelectionModal 
        servers={availableServers}
        onComplete={completeOnboarding}
      />
    );
  }

  return (
    <div className="dashboard-container">
      <TabHeader 
        username={user.discord_name}
        tab="Dashboard"
        dateRange={dateRange}
        onDateRangeChange={handleDateRangeChange}
        showDateRange={true}
        showInsightsButton={true}
        onInsightsClick={() => {/* TODO: Handle insights click */}}
      />

      {/* Show subscription selector as modal overlay if needed */}
      {showSubscriptionSelector && (
        <SubscriptionSelector onComplete={handleSubscriptionComplete} />
      )}

      <div className="stats-grid">
        {isLoading ? (
          <>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </>
        ) : (
          <>
            <div className="stat-card">
              <h3>Unique Activities</h3>
              <div className="stat-value">{stats.totalMessages}</div>
              <div className="stat-label">{getTimeRangeLabel(dateRange)}</div>
            </div>
            <div className="stat-card">
              <h3>Active Users</h3>
              <div className="stat-value">{stats.activeUsers}</div>
              <div className="stat-label">{getTimeRangeLabel(dateRange)}</div>
            </div>
            <div className="stat-card">
              <h3>Reaction Rate</h3>
              <div className="stat-value">{stats.reactionRate}</div>
              <div className="stat-label">{getTimeRangeLabel(dateRange)}</div>
            </div>
            <div className="stat-card">
              <h3>Questions Asked</h3>
              <div className="stat-value">{stats.questionsAsked}</div>
              <div className="stat-label">{getTimeRangeLabel(dateRange)}</div>
            </div>
          </>
        )}
      </div>

      <div className="dashboard-grid">
        <section className="activity-feed">
          <h2>Recent Activity</h2>
          {isLoading ? (
            <LoadingCard />
          ) : (
            <div className="activity-list">
              {recentActivity.map(activity => (
                <div key={activity.id} className={`activity-item ${activity.type}`}>
                  {activity.type === 'message_create' && (
                    <>
                      <div className="activity-icon">💬</div>
                      <div className="activity-content">
                        <p><strong>New message</strong> in <span className={`channel ${activity.channel?.type}`}>#{activity.channel.name}</span></p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {activity.type === 'message_reply' && (
                    <>
                      <div className="activity-icon">↩️</div>
                      <div className="activity-content">
                        <p><strong>Message reply</strong> in <span className={`channel ${activity.channel?.type}`}>#{activity.channel.name}</span></p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {activity.type === 'join' && (
                    <>
                      <div className="activity-icon">👋</div>
                      <div className="activity-content">
                        <p><strong>New member joined</strong> the server</p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {activity.type === 'voice_join' && (
                    <>
                      <div className="activity-icon">🎙️</div>
                      <div className="activity-content">
                        <p><strong>User joined voice</strong> in <span className={`channel voice`}>🔊 {activity.channel.name}</span></p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {activity.type === 'voice_leave' && (
                    <>
                      <div className="activity-icon">🔇</div>
                      <div className="activity-content">
                        <p><strong>User left voice</strong> in <span className={`channel voice`}>🔊 {activity.channel.name}</span></p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {activity.type === 'voice_move' && (
                    <>
                      <div className="activity-icon">↪️</div>
                      <div className="activity-content">
                        <p>
                          <strong>User moved</strong> to <span className={`channel voice`}>🔊 {activity.channel.name}</span>
                        </p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {activity.type === 'text_channel_create' && (
                    <>
                      <div className="activity-icon">📝</div>
                      <div className="activity-content">
                        <p><strong>New text channel created:</strong> <span className={`channel text`}>#{activity.channel.name}</span></p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {activity.type === 'voice_channel_create' && (
                    <>
                      <div className="activity-icon">🔈</div>
                      <div className="activity-content">
                        <p><strong>New voice channel created:</strong> <span className={`channel voice`}>🔊 {activity.channel.name}</span></p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {activity.type === 'threadCreate' && (
                    <>
                      <div className="activity-icon">🧵</div>
                      <div className="activity-content">
                        <p>
                          <strong>New thread created</strong> in <span className={`channel ${activity.channel?.type}`}>#{activity.channel.name}</span>
                        </p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {activity.type === 'ask_question' && (
                    <>
                      <div className="activity-icon">❓</div>
                      <div className="activity-content">
                        <p>
                          <strong>Question asked</strong> in <span className={`channel ${activity.channel?.type}`}>#{activity.channel.name}</span>
                          {activity.question && <span className="query">{activity.question}</span>}
                        </p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {activity.type === 'message_edit' && (
                    <>
                      <div className="activity-icon">✏️</div>
                      <div className="activity-content">
                        <p>
                          <strong>Message edited</strong> in <span className={`channel ${activity.channel?.type}`}>#{activity.channel.name}</span>
                          {activity.content && <span className="query">"{activity.content}"</span>}
                        </p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {activity.type === 'reaction' && (
                    <>
                      <div className="activity-icon">✨</div>
                      <div className="activity-content">
                        <p>
                          <strong>New reaction</strong>
                          {activity.channel && <> in <span className={`channel ${activity.channel?.type}`}>#{activity.channel.name}</span></>}
                        </p>
                        <div className="activity-meta">
                          <span className="user-name">{activity.user?.discord_name || 'Unknown user'}</span>
                          <span className="timestamp">{activity.timestamp}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </section>

        <section className="insights-panel">
          <h2>Quick Insights</h2>
          {isLoading ? (
            <LoadingCard />
          ) : (
            <div className="insights-list">
              <div className="insight-card">
                <h3>Most Active Channels</h3>
                <ul>
                  {quickInsights.map(insight => (
                    <li key={insight.server_activity_channels_id}>
                      #{insight.channel.name} <span className="count">{insight.messages_count} msgs</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="insight-card">
                <h3>Popular Topics</h3>
                <div className="topic-cloud">
                  <WordCloud 
                    topics={topics.map(topic => ({
                      topic: topic.topic,
                      count: topic.message_count || 1
                    }))}
                    width={500}
                    height={300}
                  />
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

export default Dashboard; 