import React, { useEffect } from 'react';
import './Login.css';

function Login() {
  const [currentEmoji, setCurrentEmoji] = React.useState('👋');
  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });
  const emojis = ['👋', '✨', '🚀', '💫', '🎮'];
  
  useEffect(() => {
    const handleMouseMove = (e) => {
      // Calculate mouse position relative to the center of the window
      const x = (e.clientX - window.innerWidth / 2) / 100;
      const y = (e.clientY - window.innerHeight / 2) / 100;
      setMousePosition({ x, y });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % emojis.length;
      setCurrentEmoji(emojis[currentIndex]);
    }, 2750); // Changed to 2.75 seconds (2750ms)

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // This should run immediately when they land here from Discord
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const userId = urlParams.get('user_id');
    
    // Only do anything if we have auth params
    if (token && userId) {
      fetch('https://xqx2-ksev-bf5k.n7.xano.io/api:wxo1ma9h/auth/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(data => {
        if (data.discord_id) {
          localStorage.setItem('user', JSON.stringify(data));
          localStorage.setItem('auth_token', token);
          // This should take them to the actual app interface
          window.location.replace('/app/dashboard');
        }
      });
    }
  }, []);

  const handleDiscordLogin = () => {
    window.location.href = 'https://discord.com/oauth2/authorize?client_id=1330395313479090318&redirect_uri=https://xqx2-ksev-bf5k.n7.xano.io/api:Oqa_amCh/redirect&response_type=code&scope=identify%20guilds';
  };

  const headingStyle = {
    transform: `translate(${-mousePosition.x}px, ${-mousePosition.y}px)`,
    transition: 'transform 0.1s ease-out'
  };

  return (
    <div className="login">
      <div className="gradient-orb orb-1"></div>
      <div className="gradient-orb orb-2"></div>
      
      <div className="login-container">
        <div>
          <h1 style={headingStyle}>Hello <span key={currentEmoji} className="emoji-cycle">{currentEmoji}</span></h1>
          <h2>Transform Your Discord Community Today</h2>
          <p>Join thousands of communities using AI to better understand and engage their members.</p>
          
          <button 
            className="discord-login"
            onClick={handleDiscordLogin}
          >
            <svg height="24" width="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03z"/>
            </svg>
            Continue with Discord
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login; 