import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import * as d3 from 'd3';
import cloud from 'd3-cloud';
import './WordCloud.css';

const WordCloud = ({ topics, width = 600, height = 400 }) => {
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [cloudWords, setCloudWords] = useState([]);
  const isInitialRender = useRef(true);
  
  // Process topics data
  const words = useMemo(() => {
    if (!topics || topics.length === 0) return [];

    // Group similar topics and count their frequency
    const topicGroups = topics.reduce((acc, topic) => {
      const mainTopic = topic.topic.split(':')[0].trim();
      acc[mainTopic] = (acc[mainTopic] || 0) + 1;
      return acc;
    }, {});

    // Create log scale for smoother size distribution
    const frequencies = Object.values(topicGroups);
    const sizeScale = d3.scaleLog()
      .domain([Math.min(...frequencies), Math.max(...frequencies)])
      .range([14, 38])  // More moderate size range
      .clamp(true);     // Prevent out-of-range values
    
    return topics.map(topic => {
      const [category, text] = topic.topic.split(': ');
      const mainTopic = category || topic.topic.split(':')[0].trim();
      const frequency = topicGroups[mainTopic];
      
      // Get size from log scale and round to nearest even number
      const size = Math.round(sizeScale(frequency) / 2) * 2;

      return {
        text: text || topic.topic,
        size,
        value: frequency,
        category: category || 'General',
        related: topics
          .filter(t => 
            t.topic !== topic.topic && 
            t.topic.toLowerCase().includes(mainTopic.toLowerCase())
          )
          .map(t => ({
            text: t.topic.split(': ')[1] || t.topic,
            count: topicGroups[t.topic.split(':')[0].trim()]
          }))
      };
    });
  }, [topics]);

  // Color scale
  const colorScale = d3.scaleOrdinal()
    .domain(['Development', 'Gaming', 'Community', 'Support', 'General'])
    .range(['#7289DA', '#43B581', '#FAA61A', '#F04747', '#99AAB5']);

  // Calculate cloud layout once
  useEffect(() => {
    if (!words.length) return;

    // Calculate layout just once per words change
    cloud()
      .size([width, height])
      .words(words)
      .padding(3)
      .rotate(() => 0)
      .fontSize(d => d.size)
      .on("end", calculatedWords => {
        setCloudWords(calculatedWords);
      })
      .start();
  }, [words, width, height]);

  // Setup drag behavior once
  const setupDrag = useCallback(() => {
    if (!svgRef.current || !containerRef.current) return;
    
    const svg = d3.select(svgRef.current);
    const container = d3.select(containerRef.current);

    // Setup drag behavior
    const drag = d3.drag()
      .on("start", () => {
        svg.style("cursor", "grabbing");
      })
      .on("drag", (event) => {
        // Get current transform
        const transform = container.attr("transform");
        const translateMatch = transform.match(/translate\(([^,]+),([^)]+)\)/);
        
        if (!translateMatch) return;
        
        const currentX = parseFloat(translateMatch[1]);
        const currentY = parseFloat(translateMatch[2]);
        
        // Apply new transform directly without state update
        container.attr("transform", 
          `translate(${currentX + event.dx},${currentY + event.dy}) scale(${scale})`);
      })
      .on("end", () => {
        svg.style("cursor", "grab");
        
        // Update state only at end of drag for smooth experience
        const transform = container.attr("transform");
        const translateMatch = transform.match(/translate\(([^,]+),([^)]+)\)/);
        
        if (translateMatch) {
          const endX = parseFloat(translateMatch[1]) - width/2;
          const endY = parseFloat(translateMatch[2]) - height/2;
          setPosition({ x: endX, y: endY });
        }
      });

    // Apply drag to SVG
    svg.style("cursor", "grab").call(drag);
  }, [scale, width, height]);

  // Render words
  useEffect(() => {
    if (!cloudWords.length || !svgRef.current) return;
    
    const svg = d3.select(svgRef.current);
    
    // Only clear and initialize on first render or when words/scale change
    if (isInitialRender.current) {
      svg.selectAll("*").remove();
      svg.attr("width", width)
         .attr("height", height);

      // Create a container for centering
      const container = svg.append("g")
        .attr("transform", `translate(${width/2 + position.x},${height/2 + position.y}) scale(${scale})`)
        .attr("class", "word-cloud-container-g");
      
      containerRef.current = container.node();

      // Add words
      container.selectAll("text")
        .data(cloudWords)
        .enter().append("text")
        .style("font-size", d => `${d.size}px`)
        .style("font-family", "Inter, system-ui, sans-serif")
        .style("fill", d => colorScale(d.category))
        .attr("text-anchor", "middle")
        .attr("transform", d => `translate(${d.x},${d.y})`)
        .attr("data-category", d => d.category)
        .text(d => d.text)
        .style("cursor", "pointer")
        .on("click", (event, d) => {
          event.stopPropagation();
          setSelectedWord(selectedWord?.text === d.text ? null : d);
        });
      
      isInitialRender.current = false;
      
      // Setup drag
      setupDrag();
    } else {
      // Just update the transform on subsequent renders
      if (containerRef.current) {
        d3.select(containerRef.current)
          .attr("transform", `translate(${width/2 + position.x},${height/2 + position.y}) scale(${scale})`);
      }
    }
  }, [cloudWords, width, height, scale, colorScale, position, setupDrag, selectedWord]);

  // Reset when words change
  useEffect(() => {
    isInitialRender.current = true;
  }, [words]);

  const handleZoom = (factor) => {
    setScale(prev => Math.max(0.5, Math.min(3, prev + factor)));
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  return (
    <div className="word-cloud-container" onClick={() => setSelectedWord(null)}>
      <svg ref={svgRef} className="word-cloud"></svg>
      
      <div className="cloud-controls">
        <button className="reset-button" onClick={handleReset} title="Reset position and zoom">
          ↺
        </button>
        <div className="zoom-controls">
          <button onClick={(e) => { e.stopPropagation(); handleZoom(0.2); }} title="Zoom in">+</button>
          <button onClick={(e) => { e.stopPropagation(); handleZoom(-0.2); }} title="Zoom out">−</button>
        </div>
      </div>
      
      {selectedWord && (
        <div className="related-words-panel" onClick={e => e.stopPropagation()}>
          <div className="panel-header">
            <div className="panel-title">
              <h3>{selectedWord.text}</h3>
              <span className="category-badge">{selectedWord.category}</span>
            </div>
            <button 
              className="close-button" 
              onClick={() => setSelectedWord(null)}
              title="Close panel"
            >
              ×
            </button>
          </div>
          <div className="related-words-content">
            <div className="word-details">
              <span className="detail-label">Messages:</span>
              <span className="detail-value">{selectedWord.value}</span>
              <span className="detail-label">Related Topics:</span>
              <span className="detail-value">{selectedWord.related.length}</span>
            </div>
            <div className="related-words-list">
              <h4>Related Topics</h4>
              {selectedWord.related.length > 0 ? (
                selectedWord.related
                  .sort((a, b) => b.count - a.count)
                  .map((related, index) => (
                    <div key={index} className="related-word">
                      <span className="related-text">{related.text}</span>
                      <span className="related-count">{related.count} msgs</span>
                    </div>
                  ))
              ) : (
                <p className="no-related">No related topics found</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WordCloud; 