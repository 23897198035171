import React, { useState, useRef, useEffect } from 'react';
import { FiUploadCloud, FiFile, FiX, FiSearch, FiCheck, FiImage, FiFileText, FiPaperclip, FiTag, FiPlus, FiHash, FiChevronLeft, FiChevronRight, FiExternalLink, FiHardDrive } from 'react-icons/fi';
import './DocumentUpload.css';
import { useServer } from '../contexts/ServerContext.js';
import { handleApiCall } from '../utils/api.js';
import UploadStatusWindow from './UploadStatusWindow.js';

// Helper functions for upload tracking
const getOngoingUploads = () => {
  try {
    return JSON.parse(localStorage.getItem('ongoingUploads') || '[]');
  } catch (e) {
    console.error('Error parsing ongoing uploads:', e);
    return [];
  }
};

const saveOngoingUpload = (upload) => {
  try {
    const uploads = getOngoingUploads();
    uploads.push({
      ...upload,
      startTime: Date.now(),
      status: 'processing'
    });
    localStorage.setItem('ongoingUploads', JSON.stringify(uploads));
  } catch (e) {
    console.error('Error saving ongoing upload:', e);
  }
};

const updateUploadStatus = (uploadId, status, details = {}) => {
  try {
    const uploads = getOngoingUploads();
    const index = uploads.findIndex(u => u.id === uploadId);
    if (index !== -1) {
      uploads[index] = {
        ...uploads[index],
        ...details,
        status,
        lastUpdated: Date.now()
      };
      localStorage.setItem('ongoingUploads', JSON.stringify(uploads));
    }
  } catch (e) {
    console.error('Error updating upload status:', e);
  }
};

// Helper function to update file modal state in localStorage
const updateFileModalState = (isOpen) => {
  try {
    localStorage.setItem('fileModalOpen', JSON.stringify(isOpen));
  } catch (e) {
    console.error('Error updating file modal state:', e);
  }
};

const DocumentUpload = () => {
  const [dragging, setDragging] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [channels, setChannels] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFileModal, setShowFileModal] = useState(false);
  const [fileEntries, setFileEntries] = useState([]); // Single array for all file data
  const [activeFileIndex, setActiveFileIndex] = useState(0);
  const [commonChannels, setCommonChannels] = useState([]);
  const [applyToAllFiles, setApplyToAllFiles] = useState(false);
  const [fileStats, setFileStats] = useState([]);
  const [isLoadingStats, setIsLoadingStats] = useState(false);
  const [serverStatus, setServerStatus] = useState('checking');
  const [ongoingUploads, setOngoingUploads] = useState([]);
  
  const fileInputRef = useRef(null);
  const { activeAccount, accounts } = useServer();

  // Update localStorage when showFileModal changes
  useEffect(() => {
    updateFileModalState(showFileModal);
  }, [showFileModal]);

  // Fetch channels when component mounts or active server changes
  useEffect(() => {
    const fetchChannels = async () => {
      if (!activeAccount) {
        console.log('No active account, skipping fetch');
        return;
      }

      const account = accounts.find(acc => acc.id === activeAccount);
      if (!account?.discord_server_id) {
        console.log('No discord_server_id found for account:', account);
        return;
      }

      console.log('Fetching channels for Discord server:', account.discord_server_id);
      try {
        const response = await handleApiCall({
          endpoint: `https://xqx2-ksev-bf5k.n7.xano.io/api:HZtICWLN/server/channels?discord_server_id=${account.discord_server_id}`,
          method: 'GET',
          headers: {
        
          }
        });

        console.log('API Response:', response);
        if (response && response.channels) {
          // Format channels from the response
          const textChannels = response.channels
            .filter(channel => channel.name && channel.id) // Filter channels with name and id
            .map(channel => ({
              id: channel.id.toString(), // Convert id to string for consistent comparison
              name: channel.name,
              topic: channel.description || '' // Use description as topic
            }));
          
          console.log('Formatted channels:', textChannels);
          setChannels(textChannels);
          setFilteredChannels(textChannels);
        } else {
          console.log('No channels found in response:', response);
        }
      } catch (error) {
        console.error('Error fetching channels:', error);
        console.error('Error details:', {
          message: error.message,
          status: error.status,
          response: error.response
        });
      }
    };

    fetchChannels();
  }, [activeAccount, accounts]);

  // Filter channels based on search query
  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredChannels(channels);
    } else {
      const filtered = channels.filter(channel => 
        channel.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        channel.topic.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredChannels(filtered);
    }
  }, [searchQuery, channels]);

  // Add a useEffect to log when the component mounts
  useEffect(() => {
    console.log('DocumentUpload component mounted');
    console.log('fileInputRef:', fileInputRef);
  }, []);

  // Add a useEffect to log fileEntries changes
  useEffect(() => {
    if (fileEntries.length > 0 && activeFileIndex >= 0 && activeFileIndex < fileEntries.length) {
      console.log('fileEntries changed:', fileEntries.length, 'files');
      console.log('Active file:', activeFileIndex);
      console.log('Selected channels for active file:', 
        fileEntries[activeFileIndex].selectedChannels || []);
    }
  }, [fileEntries, activeFileIndex]);

  // Fetch file stats when component mounts or when active account changes
  useEffect(() => {
    const fetchFileStats = async () => {
      if (!activeAccount) {
        console.log('No active account, skipping file stats fetch');
        return;
      }

      const account = accounts.find(acc => acc.id === activeAccount);
      if (!account?.discord_server_id) {
        console.log('No discord_server_id found for account:', account);
        return;
      }

      console.log('Fetching file stats for server:', account.discord_server_id);
      try {
        setIsLoadingStats(true);
        const response = await handleApiCall({
          endpoint: `https://xqx2-ksev-bf5k.n7.xano.io/api:HZtICWLN/documents/stats?discord_server_id=${account.discord_server_id}`,
          method: 'GET'
        });

        if (response) {
          console.log('File stats response:', response);
          setFileStats(response);
        }
      } catch (error) {
        console.error('Error fetching file stats:', error);
        setFileStats([]);
      } finally {
        setIsLoadingStats(false);
      }
    };

    fetchFileStats();
  }, [activeAccount, accounts]);

  // Fetch file stats when selected channels change
  useEffect(() => {
    const fetchFileStatsForChannels = async () => {
      if (!activeAccount) {
        console.log('No active account, skipping channel stats fetch');
        return;
      }

      const account = accounts.find(acc => acc.id === activeAccount);
      if (!account?.discord_server_id) {
        console.log('No discord_server_id found for account:', account);
        return;
      }

      // Get unique channel IDs from all file entries
      const selectedChannelIds = new Set();
      fileEntries.forEach(entry => {
        entry.selectedChannels?.forEach(channelId => {
          selectedChannelIds.add(channelId);
        });
      });

      if (selectedChannelIds.size === 0) {
        console.log('No channels selected, skipping stats fetch');
        return;
      }

      console.log('Fetching file stats for channels:', Array.from(selectedChannelIds));
      try {
        setIsLoadingStats(true);
        const channelIdsParam = Array.from(selectedChannelIds).join(',');
        const response = await handleApiCall({
          endpoint: `https://xqx2-ksev-bf5k.n7.xano.io/api:HZtICWLN/documents/stats?discord_server_id=${account.discord_server_id}&discord_channels_id=${channelIdsParam}`,
          method: 'GET'
        });

        if (response) {
          console.log('Channel file stats response:', response);
          setFileStats(response);
        }
      } catch (error) {
        console.error('Error fetching channel file stats:', error);
        setFileStats([]);
      } finally {
        setIsLoadingStats(false);
      }
    };

    fetchFileStatsForChannels();
  }, [activeAccount, accounts, fileEntries]);

  // Check if the document processor server is running
  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        console.log('Checking document processor server status...');
        
        // Use AbortController for timeout
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 3000);
        
        try {
          const response = await fetch('http://localhost:3003/health', { 
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
            signal: controller.signal
          });
          
          clearTimeout(timeoutId);
          
          if (response.ok) {
            setServerStatus('online');
            console.log('Document processor server is online');
          } else {
            setServerStatus('offline');
            console.error('Document processor server returned unexpected status:', response.status);
          }
        } catch (fetchError) {
          clearTimeout(timeoutId);
          
          if (fetchError.name === 'AbortError') {
            console.error('Document processor server request timed out');
          } else {
            console.error('Error connecting to document processor server:', fetchError);
          }
          
          setServerStatus('offline');
        }
      } catch (error) {
        setServerStatus('offline');
        console.error('Document processor server check failed:', error);
      }
    };

    checkServerStatus();
  }, []);

  // Add a useEffect to load ongoing uploads from localStorage
  useEffect(() => {
    const uploads = getOngoingUploads();
    setOngoingUploads(uploads);
    
    // Set up interval to check for updates
    const interval = setInterval(() => {
      const freshUploads = getOngoingUploads();
      setOngoingUploads(freshUploads);
      
      // Clean up old uploads (older than 24 hours)
      const oneDayAgo = Date.now() - (24 * 60 * 60 * 1000);
      const filteredUploads = freshUploads.filter(upload => 
        upload.lastUpdated > oneDayAgo || upload.startTime > oneDayAgo
      );
      
      if (filteredUploads.length !== freshUploads.length) {
        localStorage.setItem('ongoingUploads', JSON.stringify(filteredUploads));
        setOngoingUploads(filteredUploads);
      }
    }, 5000);
    
    return () => clearInterval(interval);
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      addNewFiles(droppedFiles);
    }
  };

  const handleFileSelect = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    
    const selectedFiles = Array.from(e.target.files);
    addNewFiles(selectedFiles);
    
    // Reset input
    e.target.value = null;
    
    // Prevent event propagation to avoid double dialog
    e.stopPropagation();
  };

  const removeFile = (index) => {
    setFileEntries(prev => {
      const newEntries = [...prev];
      newEntries.splice(index, 1);
      
      // Adjust active index if necessary
      if (index === activeFileIndex) {
        setActiveFileIndex(Math.max(0, index - 1));
      } else if (index < activeFileIndex) {
        setActiveFileIndex(prev => prev - 1);
      }
      
      // Close modal if no files left
      if (newEntries.length === 0) {
        setShowFileModal(false);
      }
      
      return newEntries;
    });
  };

  const removeFileFromModal = (index, e) => {
    if (e) {
      e.stopPropagation();
    }
    removeFile(index);
  };

  const handleDisplayNameChange = (e) => {
    const updatedEntries = [...fileEntries];
    updatedEntries[activeFileIndex].displayName = e.target.value;
    setFileEntries(updatedEntries);
  };

  const handleTagInputChange = (e) => {
    const updatedEntries = [...fileEntries];
    updatedEntries[activeFileIndex].tagInput = e.target.value;
    setFileEntries(updatedEntries);
  };

  const handleTagInputKeyDown = (e) => {
    if (e.key === 'Enter' && fileEntries[activeFileIndex].tagInput.trim()) {
      addTag(fileEntries[activeFileIndex].tagInput.trim());
      e.preventDefault();
    }
  };

  const addTag = (tag) => {
    const updatedEntries = [...fileEntries];
    const currentEntry = updatedEntries[activeFileIndex];
    
    // Only add if it's not a duplicate
    if (!currentEntry.tags.includes(tag)) {
      currentEntry.tags.push(tag);
      currentEntry.tagInput = '';
      
      // If apply to all files is checked, add the tag to all files
      if (applyToAllFiles) {
        updatedEntries.forEach(entry => {
          if (!entry.tags.includes(tag)) {
            entry.tags.push(tag);
          }
        });
      }
      
      setFileEntries(updatedEntries);
    }
  };

  const removeTag = (tagIndex) => {
    const updatedEntries = [...fileEntries];
    const tag = updatedEntries[activeFileIndex].tags[tagIndex];
    updatedEntries[activeFileIndex].tags.splice(tagIndex, 1);
    
    // If apply to all files is checked, remove the tag from all files
    if (applyToAllFiles) {
      updatedEntries.forEach(entry => {
        const idx = entry.tags.indexOf(tag);
        if (idx !== -1) {
          entry.tags.splice(idx, 1);
        }
      });
    }
    
    setFileEntries(updatedEntries);
  };

  const toggleChannel = (channelId) => {
    if (!channelId) return;
    
    console.log('BEFORE toggle - activeFileIndex:', activeFileIndex);
    console.log('BEFORE toggle - channelId:', channelId);
    
    setFileEntries(prev => {
      // Create a new array with properly copied entries
      const newEntries = prev.map(entry => {
        // Keep the same file reference but copy other properties
        return {
          ...entry,
          file: entry.file, // Explicitly keep the file reference
          selectedChannels: entry === prev[activeFileIndex] 
            ? updateChannels(entry.selectedChannels || [], channelId)
            : [...(entry.selectedChannels || [])]
        };
      });

      // If apply to all is enabled, sync channels across all files
      if (applyToAllFiles) {
        const activeChannels = newEntries[activeFileIndex].selectedChannels;
        newEntries.forEach(entry => {
          entry.selectedChannels = [...activeChannels];
        });
      }

      console.log('AFTER toggle - selectedChannels:', newEntries[activeFileIndex].selectedChannels);
      console.log('File still valid?', newEntries[activeFileIndex].file instanceof Blob);
      
      return newEntries;
    });
  };

  // Helper function to update channels array
  const updateChannels = (channels, channelId) => {
    const idStr = channelId.toString();
    const index = channels.indexOf(idStr);
    
    if (index > -1) {
      console.log(`Removing channel ${idStr} from selection`);
      return channels.filter(id => id !== idStr);
    } else {
      console.log(`Adding channel ${idStr} to selection`);
      return [...channels, idStr];
    }
  };

  const toggleApplyToAll = () => {
    setApplyToAllFiles(!applyToAllFiles);
    
    if (!applyToAllFiles) {
      // When turning on "apply to all", sync the current file's channels to all files
      const currentChannels = fileEntries[activeFileIndex].selectedChannels;
      const updatedEntries = [...fileEntries];
      
      updatedEntries.forEach(entry => {
        entry.selectedChannels = [...currentChannels];
      });
      
      setFileEntries(updatedEntries);
      setCommonChannels(currentChannels);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const uploadFiles = async () => {
    if (fileEntries.length === 0) return;
    
    console.log('Starting upload with fileEntries:', fileEntries.length, 'files');
    
    setIsUploading(true);
    setUploadProgress(0);
    setUploadStatus(null);
    
    // Close the file modal immediately to show the main upload screen
    setShowFileModal(false);
    
    // Check if document processor server is online
    if (serverStatus !== 'online') {
      setUploadStatus({
        type: 'error',
        message: 'Document processor server is offline. Please try again later.'
      });
      setIsUploading(false);
      return;
    }
    
    // Define progressInterval outside try block so it's accessible in catch block
    let progressInterval;
    
    try {
      // Start progress updates
      progressInterval = setInterval(() => {
        setUploadProgress(prev => Math.min(90, prev + 5));
      }, 200);
      
      // Get the active Discord server ID
      const account = accounts.find(acc => acc.id === activeAccount);
      if (!account?.discord_server_id) {
        throw new Error('No Discord server ID found for the active account');
      }
      
      console.log('Using Discord server ID:', account.discord_server_id);
      
      // Get auth token from localStorage
      const authToken = localStorage.getItem('auth_token');
      console.log('Auth token available:', authToken ? 'Yes' : 'No');
      
      // Create upload tracking entries
      const uploadBatch = {
        id: `batch_${Date.now()}`,
        files: [],
        totalFiles: fileEntries.length,
        completedFiles: 0,
        serverId: account.discord_server_id
      };
      
      // Process each file entry
      for (let i = 0; i < fileEntries.length; i++) {
        const entry = fileEntries[i];
        console.log(`Processing file ${i}: ${entry.displayName}`);
        
        if (!(entry.file instanceof Blob)) {
          console.error(`File is not a valid Blob: ${entry.displayName}`);
          continue;
        }
        
        // Create unique ID for this file upload
        const uploadId = `upload_${Date.now()}_${i}`;
        
        // Add to tracking
        uploadBatch.files.push({
          id: uploadId,
          name: entry.displayName,
          size: entry.file.size,
          type: entry.file.type,
          channels: entry.selectedChannels.map(id => {
            const channel = channels.find(c => c.id === id);
            return channel ? channel.name : id;
          }),
          tags: entry.tags
        });
        
        // Create FormData for file upload to document processor
        const formData = new FormData();
        
        // Append file with custom filename
        const file = new File([entry.file], entry.displayName, { type: entry.file.type });
        formData.append('document', file);
        
        // Add metadata
        formData.append('userId', account.discord_server_id);
        formData.append('metadata', JSON.stringify({
          name: entry.displayName,
          type: entry.file.type,
          size: entry.file.size,
          tags: entry.tags,
          discord_server_id: account.discord_server_id,
          serverId: String(account.server?.id || account.id),
          discord_channel_ids: entry.selectedChannels || [],
          uploadId: uploadId // Add upload ID to track this specific file
        }));
        
        console.log('Sending file to document processor server:', entry.displayName);
        
        // Save to localStorage before sending
        saveOngoingUpload({
          id: uploadId,
          fileName: entry.displayName,
          fileSize: entry.file.size,
          fileType: entry.file.type,
          channels: entry.selectedChannels.map(id => {
            const channel = channels.find(c => c.id === id);
            return channel ? channel.name : id;
          }),
          tags: entry.tags,
          serverId: account.discord_server_id
        });
        
        // Send the request to document processor server
        const response = await fetch('http://localhost:3003/upload', {
          method: 'POST',
          headers: {
            'Authorization': authToken ? `Bearer ${authToken}` : ''
          },
          body: formData
        });
        
        console.log('Upload response status:', response.status);
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          
          // Update localStorage with error
          updateUploadStatus(uploadId, 'error', { 
            error: `Upload failed with status ${response.status}` 
          });
          
          throw new Error(`Upload failed with status ${response.status}: ${errorText}`);
        }
        
        const responseData = await response.json();
        console.log('Upload response data:', responseData);
        
        // Update localStorage with success and document ID
        updateUploadStatus(uploadId, 'completed', { 
          documentId: responseData.documentId,
          message: 'Upload completed successfully'
        });
        
        // Update batch progress
        uploadBatch.completedFiles++;
      }
      
      // Handle success
      clearInterval(progressInterval);
      setUploadProgress(100);
      setUploadStatus({
        type: 'success',
        message: `Upload complete! Your document${fileEntries.length > 1 ? 's' : ''} will appear in the browse section shortly.`
      });
      
      // Reset file entries after successful upload
      setFileEntries([]);
      setShowFileModal(false);
      
      setIsUploading(false);
      
    } catch (error) {
      console.error('Upload error:', error);
      
      clearInterval(progressInterval);
      setUploadProgress(0);
      
      // Parse error message for better user feedback
      let errorMessage = 'An unknown error occurred during upload.';
      
      try {
        // Try to parse the error message as JSON
        if (error.message.includes('Upload failed with status')) {
          const match = error.message.match(/Upload failed with status (\d+): (.+)/);
          if (match) {
            const statusCode = match[1];
            const errorText = match[2];
            
            try {
              const errorJson = JSON.parse(errorText);
              
              if (errorJson.error) {
                errorMessage = errorJson.error;
                
                // Provide more specific messages for common errors
                if (errorMessage.includes('Authentication failed')) {
                  errorMessage = 'Your login session has expired. Please log out and log back in to continue.';
                } else if (statusCode === '500') {
                  errorMessage = `Server error: ${errorJson.error}. Please try again later or contact support.`;
                }
              } else if (errorJson.message) {
                errorMessage = errorJson.message;
              }
            } catch (jsonError) {
              // If not valid JSON, use the error text directly
              errorMessage = `Error (${statusCode}): ${errorText}`;
            }
          }
        } else {
          errorMessage = error.message;
        }
      } catch (parseError) {
        console.error('Error parsing error message:', parseError);
      }
      
      setUploadStatus({
        type: 'error',
        message: errorMessage
      });
      
      setIsUploading(false);
    }
  };

  const getFileIcon = (file) => {
    // Simple null check
    if (!file || !file.type) {
      return <FiFile />;
    }
    
    // More detailed file type check
    if (file.type.startsWith('image/')) {
      return <FiImage />;
    } else if (file.type.includes('pdf')) {
      return <FiFileText />;
    } else if (file.type.includes('word') || file.type.includes('docx')) {
      return <FiFileText />;
    } else if (file.type.includes('text') || file.type.includes('txt') || file.type.includes('md')) {
      return <FiFileText />;
    } else {
      return <FiFile />;
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  // Check if files can be uploaded
  const canUpload = fileEntries.length > 0 && fileEntries.every(entry => {
    // For direct upload from main screen, assign default channels if none selected
    if (entry.selectedChannels.length === 0 && channels.length > 0) {
      // Assign the first channel as default if no channels are selected
      entry.selectedChannels = [channels[0].id];
      return true;
    }
    return entry.displayName.trim() && entry.selectedChannels.length > 0;
  });

  const closeFileModal = () => {
    // Only close if there are no files or user confirms
    if (fileEntries.length === 0) {
      setShowFileModal(false);
    } else {
      // In a real app, you might want to add a confirmation dialog here
      setShowFileModal(false);
    }
  };

  const getTotalSize = () => {
    return formatFileSize(fileEntries.reduce((total, entry) => total + entry.file.size, 0));
  };

  const navigateToNextFile = () => {
    if (activeFileIndex < fileEntries.length - 1) {
      setActiveFileIndex(activeFileIndex + 1);
    }
  };

  const navigateToPreviousFile = () => {
    if (activeFileIndex > 0) {
      setActiveFileIndex(activeFileIndex - 1);
    }
  };

  // Helper function to add new files
  const addNewFiles = (files) => {
    console.log('Adding new files:', files);
    
    // Filter out duplicates
    const existingNames = new Set(fileEntries.map(entry => entry.file.name));
    const newFiles = files.filter(file => !existingNames.has(file.name));
    
    if (newFiles.length === 0) return;
    
    // Create entries and validate files
    const newEntries = newFiles.map(file => {
      // Log the incoming file object
      console.log('Processing file:', {
        name: file.name,
        type: file.type,
        size: file.size,
        isBlob: file instanceof Blob
      });
      
      // Validate file is a valid Blob
      if (!(file instanceof Blob)) {
        console.error('Invalid file object:', file);
        return null;
      }
      
      // Create a new File object to ensure we have a clean reference
      const fileClone = new File([file], file.name, {
        type: file.type,
        lastModified: file.lastModified
      });
      
      console.log('Created file clone:', {
        name: fileClone.name,
        type: fileClone.type,
        size: fileClone.size,
        isBlob: fileClone instanceof Blob
      });
      
      // Store the file directly without any JSON serialization
      return {
        id: Math.random().toString(36).substr(2, 9),
        file: fileClone,
        displayName: file.name,
        tags: [],
        selectedChannels: [],
        tagInput: ''
      };
    }).filter(entry => entry !== null);
    
    if (newEntries.length === 0) {
      console.error('No valid files to add');
      return;
    }
    
    // Update state using a callback to ensure we don't lose the Blob references
    setFileEntries(prev => {
      const updated = [...prev, ...newEntries];
      console.log('Updated file entries:', updated.map(entry => ({
        name: entry.file.name,
        isBlob: entry.file instanceof Blob,
        size: entry.file.size
      })));
      setActiveFileIndex(prev.length);
      setShowFileModal(true);
      return updated;
    });
  };

  return (
    <div className="document-upload-container">
      <div className="upload-header">
        <div className="header-content">
          <h2>Upload Documents</h2>
          <p>Upload documents to make them searchable in your Discord server</p>
        </div>
        
        <div className="status-badges">
          {serverStatus === 'checking' && (
            <div className="status-badge checking">
              Checking server...
            </div>
          )}
          
          {serverStatus === 'offline' && (
            <div className="status-badge offline">
              Server offline
            </div>
          )}
          
          {serverStatus === 'online' && (
            <div className="status-badge online">
              Server online
            </div>
          )}
        </div>
      </div>
      
      <div 
        className={`upload-area ${dragging ? 'dragging' : ''} ${isUploading ? 'uploading' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
      >
        <div className="upload-message">
          <div className="upload-icon">
            <FiUploadCloud />
          </div>
          <h3>Drag & drop files here</h3>
          <p>or select files from your computer</p>
          <div 
            className="file-select-button"
          >
            <span className="button-text">Browse Files</span>
            <input 
              type="file" 
              className="file-input" 
              onChange={handleFileSelect} 
              onClick={(e) => e.stopPropagation()}
              ref={fileInputRef}
              multiple
              accept=".pdf,.docx,.txt,.md,.jpg,.jpeg,.png"
              style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
            />
          </div>
          <div className="upload-formats">
            Supported formats: PDF, DOCX, TXT, MD, JPG, PNG
          </div>
        </div>
        
        {dragging && (
          <div className="drop-overlay">
            <div className="drop-message">
              <FiUploadCloud size={48} />
              <span>Release to Upload</span>
            </div>
          </div>
        )}
      </div>
      
      <div className="document-content">
        {!showFileModal && (
          <>
            {fileEntries.length > 0 && (
              <div className="selected-files">
                <h3>Selected Files ({fileEntries.length})</h3>
                <div className="files-list">
                  {fileEntries.map((entry, index) => (
                    <div className="file-item" key={index}>
                      <div className="file-info">
                        <div className="file-icon">
                          {getFileIcon(entry.file)}
                        </div>
                        <div className="file-details">
                          <div className="file-name">{entry.displayName}</div>
                          <div className="file-meta">
                            <span className="file-size">{formatFileSize(entry.file.size)}</span>
                            {entry.selectedChannels && entry.selectedChannels.length > 0 && (
                              <span className="file-channels">
                                <FiHash size={12} />
                                {entry.selectedChannels.length} channel{entry.selectedChannels.length !== 1 ? 's' : ''}
                              </span>
                            )}
                            {entry.tags && entry.tags.length > 0 && (
                              <span className="file-tags">
                                <FiTag size={12} />
                                {entry.tags.length} tag{entry.tags.length !== 1 ? 's' : ''}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <button 
                        className="remove-file" 
                        onClick={() => removeFile(index)}
                        disabled={isUploading}
                        aria-label="Remove file"
                      >
                        <FiX />
                      </button>
                    </div>
                  ))}
                </div>
                
                <div className="file-summary">
                  <div className="file-summary-item">
                    <span>Total Files:</span>
                    <span>{fileEntries.length}</span>
                  </div>
                  <div className="file-summary-item">
                    <span>Total Size:</span>
                    <span>{getTotalSize()}</span>
                  </div>
                  
                  {isLoadingStats ? (
                    <div className="loading-stats">
                      <div className="app-spinner small"></div>
                      Loading file statistics...
                    </div>
                  ) : fileStats.length > 0 ? (
                    <div className="file-stats-section">
                      <h4>Existing Files in Selected Channels</h4>
                      <div className="file-stats-list">
                        {fileStats.map(stat => (
                          <div key={stat.id} className="file-stat-item">
                            <div className="file-stat-info">
                              <div className="file-stat-name">
                                {getFileIcon(stat.file)}
                                {stat.file.name}
                              </div>
                              <div className="file-stat-details">
                                <span className="file-stat-type">
                                  <FiFileText size={12} style={{ marginRight: '4px' }} />
                                  {stat.file.type || 'Unknown type'}
                                </span>
                                <span className="file-stat-size">
                                  <FiHardDrive size={12} style={{ marginRight: '4px' }} />
                                  {formatFileSize(stat.file.size)}
                                </span>
                                {stat.channels && stat.channels.length > 0 && (
                                  <span className="file-stat-channels">
                                    <FiHash size={12} style={{ marginRight: '4px' }} />
                                    {stat.channels.length} channel{stat.channels.length !== 1 ? 's' : ''}
                                  </span>
                                )}
                              </div>
                              {stat.file.url && (
                                <a 
                                  href={stat.file.url} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  className="file-stat-link"
                                >
                                  <FiExternalLink size={12} style={{ marginRight: '4px' }} />
                                  View File
                                </a>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      {fileStats.length > 0 && (
                        <div className="file-stats-summary">
                          <div className="stats-summary-item">
                            <span>Total existing files:</span>
                            <span>{fileStats.length}</span>
                          </div>
                          <div className="stats-summary-item">
                            <span>Total size:</span>
                            <span>{formatFileSize(fileStats.reduce((total, stat) => total + stat.file.size, 0))}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="no-files-message">
                      No existing files found in the selected channels.
                    </div>
                  )}
                </div>
                
                <div className="document-actions" style={{ marginTop: '1.5rem' }}>
                  {isUploading ? (
                    <div className="upload-progress-container">
                      <div className="upload-progress-info">
                        <FiUploadCloud className="upload-progress-icon" />
                        <span>Uploading {fileEntries.length} file{fileEntries.length !== 1 ? 's' : ''}...</span>
                      </div>
                      <div className="upload-progress-bar-container">
                        <div 
                          className="upload-progress-bar-fill" 
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                      <div className="upload-progress-percentage">{uploadProgress}%</div>
                    </div>
                  ) : (
                    <div className="action-buttons">
                      <button 
                        className="manage-button" 
                        onClick={() => {
                          console.log('Manage Files button clicked');
                          setShowFileModal(true);
                          console.log('Modal state set to:', true);
                        }}
                        disabled={isUploading}
                      >
                        Manage Files
                      </button>
                      
                      <button 
                        className="upload-button" 
                        onClick={uploadFiles}
                        disabled={!canUpload || isUploading}
                      >
                        <FiUploadCloud style={{ marginRight: '8px' }} />
                        Upload Now
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
            
            {uploadStatus && (
              <div className={`upload-status-message ${uploadStatus.type}`}>
                {uploadStatus.type === 'success' ? <FiCheck /> : <FiX />}
                <span>{uploadStatus.message}</span>
              </div>
            )}
          </>
        )}
        
        {showFileModal && (
          <div className="file-management-modal">
            <div className="file-management-header">
              <h2>Manage Files ({fileEntries.length})</h2>
              <p>Customize file details and select channels for all your files</p>
            </div>
            
            {fileEntries.length > 0 && (
              <>
                <div className="file-management-content">
                  <div className="file-navigation">
                    <div className="file-tabs-container">
                      <div className="file-tabs-row">
                        <div className="file-tabs">
                          {fileEntries.map((entry, index) => (
                            <div 
                              key={index} 
                              className={`file-tab ${index === activeFileIndex ? 'active' : ''}`}
                              onClick={() => setActiveFileIndex(index)}
                            >
                              <div className="file-tab-icon">
                                {getFileIcon(entry.file)}
                              </div>
                              <span className="file-tab-name">{entry.displayName}</span>
                              <button 
                                className="remove-file-tab"
                                onClick={(e) => removeFileFromModal(index, e)}
                              >
                                <FiX />
                              </button>
                            </div>
                          ))}
                        </div>
                        
                        <div className="file-nav-buttons">
                          <button 
                            className="file-nav-button" 
                            onClick={navigateToPreviousFile}
                            disabled={activeFileIndex === 0}
                          >
                            <FiChevronLeft />
                          </button>
                          <button 
                            className="file-nav-button" 
                            onClick={navigateToNextFile}
                            disabled={activeFileIndex === fileEntries.length - 1}
                          >
                            <FiChevronRight />
                          </button>
                        </div>
                      </div>
                      
                      <div className="file-counter-container">
                        <div className="file-counter">
                          File {activeFileIndex + 1} of {fileEntries.length}
                        </div>
                        
                        <div className="apply-to-all-toggle">
                          <label className="apply-to-all-label">
                            <input 
                              type="checkbox" 
                              checked={applyToAllFiles} 
                              onChange={toggleApplyToAll}
                              className="apply-to-all-checkbox"
                            />
                            <span>Apply changes to all files</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {fileEntries[activeFileIndex] && (
                    <div className="file-edit-container">
                      <div className="file-preview-section">
                        <div className="file-preview-container">
                          {fileEntries[activeFileIndex].file && fileEntries[activeFileIndex].file.type && 
                           fileEntries[activeFileIndex].file.type.startsWith('image/') ? (
                            <img 
                              src={URL.createObjectURL(fileEntries[activeFileIndex].file)} 
                              alt="Preview" 
                              className="file-preview-image" 
                            />
                          ) : (
                            <div className="file-icon-large">
                              {getFileIcon(fileEntries[activeFileIndex].file)}
                            </div>
                          )}
                        </div>
                        
                        <div className="file-info-section">
                          <div className="file-name-edit">
                            <label htmlFor="file-name">File Name</label>
                            <input
                              id="file-name"
                              type="text"
                              className="file-name-input"
                              value={fileEntries[activeFileIndex].displayName}
                              onChange={handleDisplayNameChange}
                              placeholder="Enter file name"
                            />
                          </div>
                          
                          <div className="file-size-info">
                            <span>Type: {fileEntries[activeFileIndex].file.type || 'Unknown'}</span>
                            <span>Size: {formatFileSize(fileEntries[activeFileIndex].file.size)}</span>
                          </div>
                        </div>
                      </div>
                      
                      <div className="file-tags-section">
                        <label>Tags {applyToAllFiles && <span className="apply-all-badge">Applied to all files</span>}</label>
                        <div className="tags-input-container">
                          <div className="tags-list">
                            {fileEntries[activeFileIndex].tags.map((tag, tagIndex) => (
                              <div className="tag-item" key={tagIndex}>
                                <FiTag size={12} />
                                <span>{tag}</span>
                                <button 
                                  className="remove-tag"
                                  onClick={() => removeTag(tagIndex)}
                                >
                                  <FiX size={12} />
                                </button>
                              </div>
                            ))}
                            
                            <div className="tag-input-wrapper">
                              <input
                                type="text"
                                className="tag-input"
                                value={fileEntries[activeFileIndex].tagInput}
                                onChange={handleTagInputChange}
                                onKeyDown={handleTagInputKeyDown}
                                placeholder="Add tags (press Enter)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="file-channels-section">
                        <label>Select Channels {applyToAllFiles && <span className="apply-all-badge">Applied to all files</span>}</label>
                        <div className="channel-search">
                          <div className="search-icon">
                            <FiSearch />
                          </div>
                          <input
                            type="text"
                            className="channel-search-input"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search channels..."
                          />
                        </div>
                        
                        <div className="channels-grid">
                          {filteredChannels.length > 0 ? (
                            filteredChannels.map(channel => {
                              const currentSelectedChannels = fileEntries[activeFileIndex]?.selectedChannels || [];
                              const isSelected = currentSelectedChannels.includes(channel.id);
                              
                              return (
                                <div 
                                  key={channel.id}
                                  className={`channel-item ${isSelected ? 'selected' : ''}`}
                                  onClick={() => toggleChannel(channel.id)}
                                >
                                  <div className="channel-info">
                                    <div className="channel-name">
                                      <FiHash size={14} style={{ marginRight: '4px' }} />
                                      {channel.name}
                                    </div>
                                    <div className="channel-topic">{channel.topic}</div>
                                  </div>
                                  <div className="channel-select-indicator">
                                    {isSelected && <FiCheck />}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="no-channels-message">
                              No channels found matching "{searchQuery}"
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="file-management-actions">
                  <button 
                    className="cancel-button"
                    onClick={closeFileModal}
                    disabled={isUploading}
                  >
                    Cancel
                  </button>
                  
                  <button 
                    className="finish-button"
                    onClick={uploadFiles}
                    disabled={!canUpload || isUploading}
                  >
                    {isUploading ? (
                      <>
                        <span className="upload-progress-text">{uploadProgress}%</span>
                        <div className="upload-progress-bar">
                          <div 
                            className="upload-progress-fill" 
                            style={{ width: `${uploadProgress}%` }}
                          ></div>
                        </div>
                      </>
                    ) : (
                      <>
                        <FiUploadCloud />
                        Upload {fileEntries.length} Files
                      </>
                    )}
                  </button>
                </div>
              </>
            )}
            
            {fileEntries.length === 0 && (
              <div className="no-files-message">
                <p>No files selected. Please add files to continue.</p>
                <button 
                  className="upload-button" 
                  onClick={(e) => {
                    e.stopPropagation(); // Stop event propagation
                    setShowFileModal(false);
                  }}
                >
                  Add Files
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      
      {/* Upload Status Window */}
      <UploadStatusWindow />
    </div>
  );
};

export default DocumentUpload;