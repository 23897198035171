import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddModeratorModal.css';

const AddModeratorModal = ({ serverId, onAdd, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Search for Discord users in the server
  const searchUsers = async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await axios.get(
        `https://xqx2-ksev-bf5k.n7.xano.io/api:wxo1ma9h/server/${serverId}/users/search`,
        {
          params: { query },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
          }
        }
      );

      setSearchResults(response.data.users || []);
    } catch (err) {
      setError('Failed to search users');
      console.error('Error searching users:', err);
    } finally {
      setLoading(false);
    }
  };

  // Debounce search
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      searchUsers(searchTerm);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  const handleSelectUser = async (user) => {
    try {
      await onAdd(user.id);
    } catch (err) {
      setError('Failed to add moderator');
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h3>Add Moderator</h3>
          <button className="close-button" onClick={onClose}>×</button>
        </div>

        <div className="modal-body">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search server members..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>

          {error && (
            <div className="error-message">
              {error}
            </div>
          )}

          <div className="search-results">
            {loading ? (
              <div className="loading-message">Searching...</div>
            ) : searchResults.length > 0 ? (
              searchResults.map(user => (
                <div 
                  key={user.id} 
                  className="user-item"
                  onClick={() => handleSelectUser(user)}
                >
                  <img 
                    src={user.avatar_url || 'default-avatar.png'} 
                    alt={user.username} 
                    className="user-avatar" 
                  />
                  <div className="user-info">
                    <span className="user-name">{user.username}</span>
                    <span className="user-discriminator">#{user.discriminator}</span>
                  </div>
                  <button className="add-user-button">
                    Add
                  </button>
                </div>
              ))
            ) : searchTerm ? (
              <div className="no-results">No users found</div>
            ) : (
              <div className="search-prompt">
                Start typing to search for server members
              </div>
            )}
          </div>
        </div>

        <div className="modal-footer">
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddModeratorModal; 