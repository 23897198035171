import React, { createContext, useContext, useState, useEffect } from 'react';
import { handleApiCall } from '../utils/api.js';

const ServerContext = createContext();

export function ServerProvider({ children }) {
  const [availableServers, setAvailableServers] = useState([]);
  const [selectedServers, setSelectedServers] = useState([]);
  const [activeServer, setActiveServer] = useState(null);
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [activeAccount, setActiveAccount] = useState(null);

  const fetchUserData = async () => {
    // Check if we have a token before making the API call
    const token = localStorage.getItem('auth_token');
    if (!token) {
      return;
    }

    try {
      const userData = await handleApiCall('https://xqx2-ksev-bf5k.n7.xano.io/api:wxo1ma9h/auth/me');
      
      if (userData) {
        console.log('User data from /auth/me:', userData);
        
        // Update localStorage with fresh data
        localStorage.setItem('user', JSON.stringify(userData));
        
        setIsOnboarded(userData.onboarded || false);
        
        if (userData.users_servers_of_user && Array.isArray(userData.users_servers_of_user)) {
          setAvailableServers(userData.users_servers_of_user);
          
          // If user is onboarded, initialize with their servers
          if (userData.onboarded && userData.users_servers_of_user.length > 0) {
            setSelectedServers(userData.users_servers_of_user.map(s => s.id));
            setActiveServer(userData.users_servers_of_user[0].id);
          }
        }

        // Set accounts if available, ensuring we have discord_server_id and server_name
        if (userData._accounts && Array.isArray(userData._accounts)) {
          const processedAccounts = userData._accounts.map(account => ({
            ...account,
            discord_server_id: account.server?.discord_server_id,
            server_name: account.server?.name
          }));
          setAccounts(processedAccounts);
          if (processedAccounts.length > 0) {
            setActiveAccount(processedAccounts[0].id);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const completeOnboarding = async (selectedServerIds) => {
    try {
      const response = await handleApiCall('https://xqx2-ksev-bf5k.n7.xano.io/api:HZtICWLN/update/onboarding', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          ids: selectedServerIds
        })
      });

      if (response) {
        // Fetch fresh user data after onboarding
        await fetchUserData();
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error completing onboarding:', error);
      return false;
    }
  };

  const switchServer = (serverId) => {
    if (selectedServers.includes(serverId)) {
      setActiveServer(serverId);
    }
  };

  const switchAccount = (accountId) => {
    if (accounts.some(acc => acc.id === accountId)) {
      setActiveAccount(accountId);
    }
  };

  return (
    <ServerContext.Provider value={{
      availableServers,
      selectedServers,
      activeServer,
      isOnboarded,
      accounts,
      activeAccount,
      completeOnboarding,
      switchServer,
      switchAccount
    }}>
      {children}
    </ServerContext.Provider>
  );
}

export function useServer() {
  const context = useContext(ServerContext);
  if (!context) {
    throw new Error('useServer must be used within a ServerProvider');
  }
  return context;
} 