import React, { useState, useEffect } from 'react';
import './DocumentManager.css';
import TabHeader from './TabHeader.js';
import DocumentUpload from './DocumentUpload.js';
import { useServer } from '../contexts/ServerContext.js';
import { handleApiCall } from '../utils/api.js';

const DocumentManager = () => {
  const [activeTab, setActiveTab] = useState('browse');
  const [isLoading, setIsLoading] = useState(true);
  const [documentStats, setDocumentStats] = useState({
    totalDocuments: 0,
    totalSize: '0 B',
    lastUpdated: '-',
    recentUploads: []
  });
  
  const { activeAccount, accounts } = useServer();

  useEffect(() => {
    const fetchDocumentStats = async () => {
      if (!activeAccount) {
        console.log('No active account, skipping stats fetch');
        return;
      }

      const account = accounts.find(acc => acc.id === activeAccount);
      if (!account?.discord_server_id) {
        console.log('No discord_server_id found for account:', account);
        return;
      }

      try {
        setIsLoading(true);
        const response = await handleApiCall({
          endpoint: `https://xqx2-ksev-bf5k.n7.xano.io/api:HZtICWLN/documents/stats?discord_server_id=${account.discord_server_id}`,
          method: 'GET'
        });

        if (response) {
          console.log('Document stats response:', response);
          
          // Calculate total size
          const totalBytes = response.reduce((sum, file) => sum + file.file.size, 0);
          const totalSize = formatFileSize(totalBytes);
          
          // Get the most recent update date
          const lastUpdated = response.length > 0 
            ? new Date(Math.max(...response.map(file => new Date(file.created_at)))).toISOString().split('T')[0]
            : '-';
          
          // Format recent uploads
          const recentUploads = response
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .slice(0, 5)
            .map(file => ({
              name: file.file.name,
              size: formatFileSize(file.file.size),
              date: new Date(file.created_at).toISOString().split('T')[0],
              status: 'processed'
            }));

          setDocumentStats({
            totalDocuments: response.length,
            totalSize,
            lastUpdated,
            recentUploads
          });
        }
      } catch (error) {
        console.error('Error fetching document stats:', error);
        setDocumentStats({
          totalDocuments: 0,
          totalSize: '0 B',
          lastUpdated: '-',
          recentUploads: []
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocumentStats();
  }, [activeAccount, accounts]);

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const CustomTabButtons = () => (
    <div className="document-tabs">
      <button 
        className={`tab-button ${activeTab === 'browse' ? 'active' : ''}`}
        onClick={() => setActiveTab('browse')}
      >
        Browse Documents
      </button>
      <button 
        className={`tab-button ${activeTab === 'upload' ? 'active' : ''}`}
        onClick={() => setActiveTab('upload')}
      >
        Upload Documents
      </button>
    </div>
  );

  return (
    <div className="document-manager">
      <TabHeader 
        tab="Documents"
        showDateRange={false}
        showInsightsButton={false}
        customButton={<CustomTabButtons />}
      />

      {activeTab === 'browse' ? (
        <div className="document-content">
          <div className="document-stats">
            <div className="stat-item">
              <span className="stat-label">Total Documents</span>
              <span className="stat-value">{documentStats.totalDocuments}</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Total Size</span>
              <span className="stat-value">{documentStats.totalSize}</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Last Updated</span>
              <span className="stat-value">{documentStats.lastUpdated}</span>
            </div>
          </div>

          <div className="recent-uploads">
            <h3>Recent Uploads</h3>
            <div className="uploads-list">
              {isLoading ? (
                <div className="loading">Loading...</div>
              ) : documentStats.recentUploads.length > 0 ? (
                documentStats.recentUploads.map((doc, index) => (
                  <div key={index} className="upload-item">
                    <div className="upload-info">
                      <span className="upload-name">{doc.name}</span>
                      <div className="upload-meta">
                        <span>{doc.size}</span>
                        <span>{doc.date}</span>
                      </div>
                    </div>
                    <span className={`upload-status ${doc.status}`}>
                      {doc.status === 'processed' ? '✓ Ready' : '⟳ Processing'}
                    </span>
                  </div>
                ))
              ) : (
                <div className="no-uploads">No documents uploaded yet</div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <DocumentUpload />
      )}
    </div>
  );
};

export default DocumentManager; 