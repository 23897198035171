import React, { useState, useEffect } from 'react';
import './Settings.css';
import { useServer } from '../contexts/ServerContext.js';
import { useServerAccess } from '../contexts/ServerAccessContext.js';
import AddModeratorModal from './AddModeratorModal.js';

const Settings = () => {
  const { accounts, availableServers, completeOnboarding } = useServer();
  const { 
    moderators, 
    purchasedSeats, 
    availableSeats,
    loading: accessLoading,
    error: accessError,
    fetchServerAccess,
    addModerator,
    removeModerator,
    purchaseSeats
  } = useServerAccess();

  const [settings, setSettings] = useState({
    servers: {
      selectedServers: accounts.map(acc => acc.id),
      analyticsEnabled: true,
      notificationsEnabled: true,
      autoSync: true,
      autoApproveMods: false
    },
    stripe: {
      isConnected: false,
      paymentMethods: [],
      billingEmail: '',
      subscriptionTier: 'free'
    },
    preferences: {
      theme: 'dark',
      language: 'en',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      dateFormat: '24h'
    },
    notifications: {
      serverUpdates: true,
      analyticsReports: true,
      securityAlerts: true,
      marketingEmails: false
    }
  });

  const [showAddModeratorModal, setShowAddModeratorModal] = useState(false);
  const [selectedServer, setSelectedServer] = useState(null);

  useEffect(() => {
    if (accounts.length > 0) {
      setSelectedServer(accounts[0].id);
      fetchServerAccess(accounts[0].id);
    }
  }, [accounts]);

  const handleServerChange = async (serverId) => {
    setSelectedServer(serverId);
    await fetchServerAccess(serverId);
  };

  const handleAddModerator = async (moderatorId) => {
    try {
      await addModerator(selectedServer, moderatorId);
      setShowAddModeratorModal(false);
    } catch (error) {
      console.error('Failed to add moderator:', error);
      // Handle error (show notification, etc.)
    }
  };

  const handleRemoveModerator = async (moderatorId) => {
    try {
      await removeModerator(selectedServer, moderatorId);
    } catch (error) {
      console.error('Failed to remove moderator:', error);
      // Handle error (show notification, etc.)
    }
  };

  const handlePurchaseSeats = async () => {
    try {
      const quantity = 1; // You might want to make this configurable
      await purchaseSeats(selectedServer, quantity);
    } catch (error) {
      console.error('Failed to purchase seats:', error);
      // Handle error (show notification, etc.)
    }
  };

  const handleSettingChange = (category, setting) => {
    setSettings(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [setting]: !prev[category][setting]
      }
    }));
  };

  const handleSelectChange = (category, setting, value) => {
    setSettings(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [setting]: value
      }
    }));
  };

  const handleLogout = () => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user');
    window.location.href = '/';
  };

  const handleServerToggle = async (server) => {
    const serverId = server.server.id;
    const newSelectedServers = settings.servers.selectedServers.includes(serverId)
      ? settings.servers.selectedServers.filter(id => id !== serverId)
      : [...settings.servers.selectedServers, serverId];

    setSettings(prev => ({
      ...prev,
      servers: {
        ...prev.servers,
        selectedServers: newSelectedServers
      }
    }));

    // Update the server selection in the backend
    await completeOnboarding(newSelectedServers);
  };

  const handleSaveSettings = async () => {
    // TODO: Implement settings save
    console.log('Saving settings:', settings);
  };

  return (
    <div className="settings">
      <div className="settings-header">
        <h2>Account Settings</h2>
        <button className="save-button" onClick={handleSaveSettings}>Save Changes</button>
      </div>

      <div className="settings-grid">
        {/* Server Settings */}
        <div className="settings-card">
          <h3>Server Settings</h3>
          <div className="settings-list">
            <div className="server-selection-list">
              {availableServers && availableServers.length > 0 ? (
                availableServers.map(server => (
                  <div 
                    key={server.server.id}
                    className={`server-selection-item ${settings.servers.selectedServers.includes(server.server.id) ? 'selected' : ''}`}
                    onClick={() => handleServerChange(server.server.id)}
                  >
                    <div className="server-info">
                      <h4>{server.server.name}</h4>
                      <p>{server.mod ? 'Admin Access' : 'Member'}</p>
                      {server.server.metadata?.member_count && (
                        <span className="member-count">
                          {server.server.metadata.member_count} members
                        </span>
                      )}
                    </div>
                    <div className="server-status">
                      {settings.servers.selectedServers.includes(server.server.id) ? (
                        <span className="status-icon selected">✓</span>
                      ) : (
                        <span className="status-icon">+</span>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-servers-message">
                  <p>No Discord servers found. Make sure you have:</p>
                  <ul>
                    <li>Authorized DiscordDB with your Discord account</li>
                    <li>Admin or moderator access to the servers you want to manage</li>
                    <li>Invited the DiscordDB bot to your servers</li>
                  </ul>
                  <button className="discord-button" onClick={() => window.location.href = '/app/login'}>
                    Reconnect Discord
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Server Access Management */}
        <div className="settings-card">
          <h3>Server Access Management</h3>
          <div className="settings-list">
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Available Seats</span>
                <span className="setting-description">
                  {accessLoading ? 'Loading...' : `${availableSeats} of ${purchasedSeats} seats available`}
                </span>
              </div>
              <button 
                className="upgrade-button"
                onClick={handlePurchaseSeats}
                disabled={accessLoading}
              >
                Add Seats
              </button>
            </div>
            
            <div className="moderator-list">
              <h4>Assigned Moderators</h4>
              {accessLoading ? (
                <div className="loading-state">Loading moderators...</div>
              ) : moderators.map((mod) => (
                <div key={mod.id} className="moderator-item">
                  <div className="moderator-info">
                    <img 
                      src={mod.avatar_url || 'default-avatar.png'} 
                      alt={mod.username} 
                      className="moderator-avatar" 
                    />
                    <div className="moderator-details">
                      <span className="moderator-name">{mod.username}</span>
                      <span className="moderator-role">{mod.role}</span>
                    </div>
                  </div>
                  <button 
                    className="remove-moderator-button"
                    onClick={() => handleRemoveModerator(mod.id)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              
              <button 
                className="add-moderator-button"
                onClick={() => setShowAddModeratorModal(true)}
                disabled={availableSeats <= 0}
              >
                <span className="plus-icon">+</span>
                Add Moderator
              </button>
            </div>

            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Auto-Approve Moderators</span>
                <span className="setting-description">Automatically approve moderator requests</span>
              </div>
              <label className="toggle">
                <input
                  type="checkbox"
                  checked={settings.servers.autoApproveMods}
                  onChange={() => handleSettingChange('servers', 'autoApproveMods')}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
          </div>
        </div>

        {/* Stripe Settings */}
        <div className="settings-card">
          <h3>Billing & Subscription</h3>
          <div className="settings-list">
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Stripe Connection</span>
                <span className="setting-description">
                  {settings.stripe.isConnected ? 'Connected to Stripe' : 'Not connected'}
                </span>
              </div>
              <button 
                className={`stripe-button ${settings.stripe.isConnected ? 'connected' : ''}`}
                onClick={() => {/* Implement Stripe connect */}}
              >
                {settings.stripe.isConnected ? 'Manage' : 'Connect'}
              </button>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Subscription Plan</span>
                <span className="setting-description">Current plan: {settings.stripe.subscriptionTier}</span>
              </div>
              <button className="upgrade-button">
                Upgrade Plan
              </button>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Billing Email</span>
                <span className="setting-description">Email for invoices and receipts</span>
              </div>
              <input
                type="email"
                className="setting-input"
                value={settings.stripe.billingEmail}
                onChange={(e) => handleSelectChange('stripe', 'billingEmail', e.target.value)}
                placeholder="Enter billing email"
              />
            </div>
          </div>
        </div>

        {/* Preferences */}


        {/* Account Management */}
        <div className="settings-card">
          <h3>Account Management</h3>
          <div className="settings-list">
            <div className="setting-item">

      
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Delete Account</span>
                <span className="setting-description">Permanently delete your account</span>
              </div>
              <button className="delete-button" onClick={() => {/* Implement delete account */}}>
                Delete
              </button>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Logout</span>
                <span className="setting-description">Sign out of your account</span>
              </div>
              <button className="logout-button" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add Moderator Modal */}
      {showAddModeratorModal && (
        <AddModeratorModal
          serverId={selectedServer}
          onAdd={handleAddModerator}
          onClose={() => setShowAddModeratorModal(false)}
        />
      )}
    </div>
  );
};

export default Settings; 