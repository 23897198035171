// Utility function for handling API calls with token expiration
export const handleApiCall = async (urlOrConfig, optionsParam = {}) => {
  try {
    let url;
    let options = { ...optionsParam };

    // Handle both string URLs and config objects
    if (typeof urlOrConfig === 'string') {
      url = urlOrConfig;
    } else {
      url = urlOrConfig.endpoint;
      options = {
        ...options,
        method: urlOrConfig.method || 'GET',
        headers: {
          ...options.headers,
          ...urlOrConfig.headers
        }
      };
      
      // Handle FormData differently than JSON data
      if (urlOrConfig.data) {
        if (urlOrConfig.data instanceof FormData) {
          options.body = urlOrConfig.data;
          // Don't set Content-Type for FormData - browser will set it with boundary
        } else {
          options.body = JSON.stringify(urlOrConfig.data);
          // Set Content-Type for JSON if not already set
          if (!options.headers['Content-Type']) {
            options.headers['Content-Type'] = 'application/json';
          }
        }
      }
    }

    // Add auth token to headers if it exists
    const token = localStorage.getItem('auth_token');
    if (token) {
      options.headers = {
        ...options.headers,
        'Authorization': `Bearer ${token}`
      };
    }

    // Log the request details for debugging
    console.log('API Request:', {
      url,
      method: options.method,
      headers: options.headers,
      bodyType: options.body instanceof FormData ? 'FormData' : typeof options.body
    });

    const response = await fetch(url, options);

    // Handle 401 Unauthorized responses
    if (response.status === 401) {
      // Clear local storage
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user');
      
      // Redirect to login page
      window.location.href = '/app/login';
      return null;
    }

    // For other error status codes, throw an error
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
}; 