// App.js
import React, { useState, useEffect } from 'react';
import './App.css'; // Import your styles
import TabNavigation from './components/TabNavigation.js';
import Dashboard from './components/Dashboard.js';
import Analytics from './components/Analytics.js';
import DocumentManager from './components/DocumentManager.js';
import Rules from './components/Rules.js';
import Settings from './components/Settings.js';
import Login from './components/Login.js'; // Import the Login component
import { ServerProvider } from './contexts/ServerContext.js';
import { ServerAccessProvider } from './contexts/ServerAccessContext.js';

function App() {
  // Initialize from localStorage
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage.getItem('auth_token');
    const user = localStorage.getItem('user');
    return Boolean(token && user);
  });
  
  const [activeTab, setActiveTab] = useState('Dashboard');

  // Keep authentication state in sync with localStorage
  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    const user = localStorage.getItem('user');
    setIsAuthenticated(Boolean(token && user));
  }, []); // Run once on mount

  const renderContent = () => {
    if (!isAuthenticated) {
      return <Login setIsAuthenticated={setIsAuthenticated} />;
    }
    
    switch (activeTab) {
      case 'Dashboard':
        return <Dashboard />;
      case 'Analytics':
        return <Analytics />;
      case 'Documents':
        return <DocumentManager />;
      case 'Rules':
        return <Rules />;
      case 'Settings':
        return <Settings />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <ServerProvider>
      <ServerAccessProvider>
        <div className="App">
          {isAuthenticated && (
            <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
          )}
          <div className="content">
            {renderContent()}
          </div>
        </div>
      </ServerAccessProvider>
    </ServerProvider>
  );
}

export default App;