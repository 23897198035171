import React, { useState } from 'react';
import './Rules.css';

const Rules = () => {
  const [ragSettings, setRagSettings] = useState({
    personality: {
      tone: 'friendly',
      formality: 'casual',
      verbosity: 'balanced',
      customPrompt: ''
    },
    response: {
      autoRespondDelay: '0', // instant
      confidenceThreshold: 0.85,
      maxTokens: 1000,
      temperature: 0.7
    },
    api: {
      provider: 'openai',
      model: 'gpt-4',
      apiKey: '',
      organizationId: ''
    },
    context: {
      windowSize: 5,
      maxSourceDocs: 4,
      recentMessagesIncluded: true,
      includeChannelContext: true
    },
    moderation: {
      filterProfanity: true,
      filterSensitiveTopics: true,
      requireSourceCitation: true,
      maxResponseLength: 2000
    }
  });

  const handleSettingChange = (category, setting, value) => {
    setRagSettings(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [setting]: value
      }
    }));
  };

  const toneOptions = [
    { value: 'friendly', label: 'Friendly & Approachable' },
    { value: 'professional', label: 'Professional & Formal' },
    { value: 'casual', label: 'Casual & Relaxed' },
    { value: 'technical', label: 'Technical & Precise' }
  ];

  const modelOptions = {
    openai: [
      { value: 'gpt-4', label: 'GPT-4 (Most Capable)' },
      { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo (Faster)' }
    ],
    anthropic: [
      { value: 'claude-3-opus', label: 'Claude 3 Opus (Most Capable)' },
      { value: 'claude-3-sonnet', label: 'Claude 3 Sonnet (Balanced)' },
      { value: 'claude-3-haiku', label: 'Claude 3 Haiku (Fastest)' }
    ]
  };

  return (
    <div className="rules">
      <div className="rules-header">
        <h2>Q&A Model Rules</h2>
        <button className="save-button">Save Changes</button>
      </div>

      <div className="rules-grid">
        {/* Personality Settings */}
        <div className="rules-card">
          <h3>Voice & Tone</h3>
          <div className="settings-list">
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Conversation Style</span>
                <span className="setting-description">How the bot should interact with users</span>
              </div>
              <select
                className="setting-select"
                value={ragSettings.personality.tone}
                onChange={(e) => handleSettingChange('personality', 'tone', e.target.value)}
              >
                {toneOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Response Length</span>
                <span className="setting-description">Preferred verbosity of responses</span>
              </div>
              <select
                className="setting-select"
                value={ragSettings.personality.verbosity}
                onChange={(e) => handleSettingChange('personality', 'verbosity', e.target.value)}
              >
                <option value="concise">Concise</option>
                <option value="balanced">Balanced</option>
                <option value="detailed">Detailed</option>
              </select>
            </div>
            <div className="setting-item full-width">
              <div className="setting-info">
                <span className="setting-label">Custom System Prompt</span>
                <span className="setting-description">Additional instructions for the bot's behavior</span>
              </div>
              <textarea
                className="setting-textarea"
                value={ragSettings.personality.customPrompt}
                onChange={(e) => handleSettingChange('personality', 'customPrompt', e.target.value)}
                placeholder="Enter additional instructions for the bot's personality and behavior..."
                rows={4}
              />
            </div>
          </div>
        </div>

        {/* Response Settings */}
        <div className="rules-card">
          <h3>Response Settings</h3>
          <div className="settings-list">
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Natural Chat Detection</span>
                <span className="setting-description">How bot handles questions without /ask command</span>
              </div>
              <select
                className="setting-select"
                value={ragSettings.response.autoRespondMode}
                onChange={(e) => handleSettingChange('response', 'autoRespondMode', e.target.value)}
              >
                <option value="disabled">Disabled (Only respond to /ask)</option>
                <option value="conservative">Conservative (High confidence only)</option>
                <option value="balanced">Balanced (Normal threshold)</option>
                <option value="eager">Eager (Lower threshold)</option>
              </select>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Response Delay</span>
                <span className="setting-description">Delay for natural chat responses (instant for /ask)</span>
              </div>
              <select
                className="setting-select"
                value={ragSettings.response.autoRespondDelay}
                onChange={(e) => handleSettingChange('response', 'autoRespondDelay', e.target.value)}
              >
                <option value="0">Instant</option>
                <option value="3">3 seconds</option>
                <option value="5">5 seconds</option>
                <option value="10">10 seconds</option>
                <option value="-1">Manual Approval</option>
              </select>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Natural Chat Confidence</span>
                <span className="setting-description">Threshold for auto-responses (doesn't affect /ask)</span>
              </div>
              <div className="range-input-container">
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.05"
                  value={ragSettings.response.confidenceThreshold}
                  onChange={(e) => handleSettingChange('response', 'confidenceThreshold', parseFloat(e.target.value))}
                />
                <span className="range-value">{(ragSettings.response.confidenceThreshold * 100).toFixed(0)}%</span>
              </div>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Temperature</span>
                <span className="setting-description">Response creativity (applies to all responses)</span>
              </div>
              <div className="range-input-container">
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={ragSettings.response.temperature}
                  onChange={(e) => handleSettingChange('response', 'temperature', parseFloat(e.target.value))}
                />
                <span className="range-value">{ragSettings.response.temperature.toFixed(1)}</span>
              </div>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Channel Restrictions</span>
                <span className="setting-description">Control where bot can auto-respond</span>
              </div>
              <select
                className="setting-select"
                value={ragSettings.response.channelMode}
                onChange={(e) => handleSettingChange('response', 'channelMode', e.target.value)}
              >
                <option value="all">All Channels</option>
                <option value="whitelist">Only Selected Channels</option>
                <option value="blacklist">Exclude Selected Channels</option>
              </select>
            </div>
          </div>
        </div>

        {/* API Settings */}
        <div className="rules-card">
          <h3>API Configuration</h3>
          <div className="settings-list">
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">API Provider</span>
                <span className="setting-description">Select your AI model provider</span>
              </div>
              <select
                className="setting-select"
                value={ragSettings.api.provider}
                onChange={(e) => handleSettingChange('api', 'provider', e.target.value)}
              >
                <option value="openai">OpenAI</option>
                <option value="anthropic">Anthropic</option>
              </select>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Model</span>
                <span className="setting-description">Select the AI model to use</span>
              </div>
              <select
                className="setting-select"
                value={ragSettings.api.model}
                onChange={(e) => handleSettingChange('api', 'model', e.target.value)}
              >
                {modelOptions[ragSettings.api.provider].map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">API Key</span>
                <span className="setting-description">Your API key for authentication</span>
              </div>
              <input
                type="password"
                className="setting-input"
                value={ragSettings.api.apiKey}
                onChange={(e) => handleSettingChange('api', 'apiKey', e.target.value)}
                placeholder="Enter your API key"
              />
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Organization ID</span>
                <span className="setting-description">Optional: Your organization identifier</span>
              </div>
              <input
                type="text"
                className="setting-input"
                value={ragSettings.api.organizationId}
                onChange={(e) => handleSettingChange('api', 'organizationId', e.target.value)}
                placeholder="Enter your organization ID (optional)"
              />
            </div>
          </div>
        </div>

        {/* Context Settings */}
        <div className="rules-card">
          <h3>Context Settings</h3>
          <div className="settings-list">
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Context Window</span>
                <span className="setting-description">Number of messages to include as context</span>
              </div>
              <select
                className="setting-select"
                value={ragSettings.context.windowSize}
                onChange={(e) => handleSettingChange('context', 'windowSize', parseInt(e.target.value))}
              >
                <option value="3">3 messages</option>
                <option value="5">5 messages</option>
                <option value="10">10 messages</option>
                <option value="15">15 messages</option>
              </select>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Max Source Documents</span>
                <span className="setting-description">Maximum number of documents to reference</span>
              </div>
              <select
                className="setting-select"
                value={ragSettings.context.maxSourceDocs}
                onChange={(e) => handleSettingChange('context', 'maxSourceDocs', parseInt(e.target.value))}
              >
                <option value="2">2 documents</option>
                <option value="4">4 documents</option>
                <option value="6">6 documents</option>
                <option value="8">8 documents</option>
              </select>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Include Recent Messages</span>
                <span className="setting-description">Use chat history for context</span>
              </div>
              <label className="toggle">
                <input
                  type="checkbox"
                  checked={ragSettings.context.recentMessagesIncluded}
                  onChange={(e) => handleSettingChange('context', 'recentMessagesIncluded', e.target.checked)}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Channel Context</span>
                <span className="setting-description">Consider channel topic and purpose</span>
              </div>
              <label className="toggle">
                <input
                  type="checkbox"
                  checked={ragSettings.context.includeChannelContext}
                  onChange={(e) => handleSettingChange('context', 'includeChannelContext', e.target.checked)}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
          </div>
        </div>

        {/* Moderation Settings */}
        <div className="rules-card">
          <h3>Moderation Settings</h3>
          <div className="settings-list">
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Filter Profanity</span>
                <span className="setting-description">Remove inappropriate language</span>
              </div>
              <label className="toggle">
                <input
                  type="checkbox"
                  checked={ragSettings.moderation.filterProfanity}
                  onChange={(e) => handleSettingChange('moderation', 'filterProfanity', e.target.checked)}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Filter Sensitive Topics</span>
                <span className="setting-description">Avoid controversial subjects</span>
              </div>
              <label className="toggle">
                <input
                  type="checkbox"
                  checked={ragSettings.moderation.filterSensitiveTopics}
                  onChange={(e) => handleSettingChange('moderation', 'filterSensitiveTopics', e.target.checked)}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Require Citations</span>
                <span className="setting-description">Include source references in responses</span>
              </div>
              <label className="toggle">
                <input
                  type="checkbox"
                  checked={ragSettings.moderation.requireSourceCitation}
                  onChange={(e) => handleSettingChange('moderation', 'requireSourceCitation', e.target.checked)}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
            <div className="setting-item">
              <div className="setting-info">
                <span className="setting-label">Max Response Length</span>
                <span className="setting-description">Maximum characters in responses</span>
              </div>
              <select
                className="setting-select"
                value={ragSettings.moderation.maxResponseLength}
                onChange={(e) => handleSettingChange('moderation', 'maxResponseLength', parseInt(e.target.value))}
              >
                <option value="1000">1,000 characters</option>
                <option value="2000">2,000 characters</option>
                <option value="3000">3,000 characters</option>
                <option value="4000">4,000 characters</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules; 