import React from 'react';
import './ThreadPanel.css';

const ThreadPanel = ({ thread, onClose }) => {
  if (!thread) return null;

  return (
    <div className="thread-panel">
      <div className="thread-panel-header">
        <h3>{thread.topic}</h3>
        <button className="close-button" onClick={onClose}>×</button>
      </div>
      <div className="thread-panel-content">
        <div className="thread-info">
          <div className="thread-meta">
            <span className="thread-date">
              {new Date(thread.day).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </span>
            <span className={`thread-sentiment ${thread.sentiment}`}>
              {thread.sentiment.charAt(0).toUpperCase() + thread.sentiment.slice(1)}
            </span>
          </div>
          <div className="message-count">
            {thread.message_count} messages
          </div>
        </div>
        <div className="messages-list">
          {thread.messages_ids?.map((messageData, index) => {
            const message = messageData.messages;
            return (
              <div key={message.id} className="message-item">
                <div className="message-time">
                  {new Date(message.sent).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </div>
                <div className="message-content">
                  {message.content}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ThreadPanel; 