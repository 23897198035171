import React from 'react';
import './TabHeader.css';

function TabHeader({ 
  username,
  tab,
  dateRange, 
  onDateRangeChange,
  showDateRange = true,
  showInsightsButton = false,
  onInsightsClick,
  customButton
}) {
  const getHeaderTitle = () => {
    switch (tab) {
      case 'Dashboard':
        return `Welcome back, ${username}`;
      case 'Analytics':
        return 'Analytics Overview';
      case 'Documents':
        return 'Document Management';
      case 'Rules':
        return 'Server Rules';
      case 'Settings':
        return 'Server Settings';
      default:
        return `Welcome back, ${username}`;
    }
  };

  return (
    <header className="tab-header">
      <div className="welcome-section">
        <h1>{getHeaderTitle()}</h1>
      </div>
      <div className="quick-actions">
        {showDateRange && (
          <div className="date-range-selector">
            <button 
              className={`range-button ${dateRange === 'today' ? 'active' : ''}`}
              onClick={() => onDateRangeChange('today')}
            >
              Today
            </button>
            <button 
              className={`range-button ${dateRange === '24h' ? 'active' : ''}`}
              onClick={() => onDateRangeChange('24h')}
            >
              24h
            </button>
            <button 
              className={`range-button ${dateRange === '7d' ? 'active' : ''}`}
              onClick={() => onDateRangeChange('7d')}
            >
              7d
            </button>
            <button 
              className={`range-button ${dateRange === '30d' ? 'active' : ''}`}
              onClick={() => onDateRangeChange('30d')}
            >
              30d
            </button>
            <button 
              className={`range-button ${dateRange === 'all' ? 'active' : ''}`}
              onClick={() => onDateRangeChange('all')}
            >
              All
            </button>
          </div>
        )}
        {showInsightsButton && (
          <button className="primary-button" onClick={onInsightsClick}>
            <span className="icon">⚡</span>
            Quick Insights
          </button>
        )}
        {customButton && customButton}
      </div>
    </header>
  );
}

export default TabHeader; 