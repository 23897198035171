import React, { useState, useEffect } from 'react';
import './ServerSelectionModal.css';

function ServerSelectionModal({ servers, onComplete }) {
  const [selectedServers, setSelectedServers] = useState([]);
  const [step, setStep] = useState('servers'); // 'servers' or 'timezone'
  const [selectedTimezone, setSelectedTimezone] = useState('');
  
  // Get system timezone on mount
  useEffect(() => {
    const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Find the region that contains the system timezone
    const region = Object.entries(timezoneGroups).find(([_, zones]) => 
      zones.includes(systemTimezone)
    );
    
    // If system timezone isn't in our groups, add it to the appropriate region
    if (!region) {
      const continent = systemTimezone.split('/')[0];
      if (timezoneGroups[continent]) {
        timezoneGroups[continent].push(systemTimezone);
      } else {
        timezoneGroups[continent] = [systemTimezone];
      }
    }
    
    setSelectedTimezone(systemTimezone);
  }, []);

  const toggleServer = (serverRecord) => {
    const serverId = serverRecord.server.id;
    if (selectedServers.includes(serverId)) {
      setSelectedServers(selectedServers.filter(id => id !== serverId));
    } else {
      setSelectedServers([...selectedServers, serverId]);
    }
  };

  const handleNext = () => {
    if (step === 'servers' && selectedServers.length > 0) {
      setStep('timezone');
    } else if (step === 'timezone') {
      onComplete({
        server_ids: selectedServers,
        timezone: selectedTimezone
      });
    }
  };

  const handleBack = () => {
    setStep('servers');
  };

  // Group timezones by region for better UX
  const timezoneGroups = {
    'America': [
      'America/Phoenix',
      'America/New_York', 
      'America/Chicago', 
      'America/Denver', 
      'America/Los_Angeles'
    ],
    'Europe': ['Europe/London', 'Europe/Paris', 'Europe/Berlin', 'Europe/Moscow'],
    'Asia': ['Asia/Dubai', 'Asia/Singapore', 'Asia/Tokyo'],
    'Pacific': ['Pacific/Auckland', 'Pacific/Honolulu']
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {step === 'servers' ? (
          <>
            <h2>Select Your Servers</h2>
            <p>Choose which Discord servers you want to manage with DiscordDB</p>
            
            <div className="server-list">
              {servers && servers.length > 0 ? (
                servers.map(server => (
                  <div 
                    key={server.id}
                    className={`server-item ${selectedServers.includes(server.server.id) ? 'selected' : ''}`}
                    onClick={() => toggleServer(server)}
                  >
                    <div className="server-info">
                      <h3>{server.server.name}</h3>
                      <p>{server.mod ? 'Admin Access' : 'Member'}</p>
                      {server.server.metadata?.member_count && (
                        <span className="member-count">
                          {server.server.metadata.member_count} members
                        </span>
                      )}
                    </div>
                    <div className="server-status">
                      {selectedServers.includes(server.server.id) ? (
                        <span className="status-icon selected">✓</span>
                      ) : (
                        <span className="status-icon">+</span>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-servers-message">
                  <p>No Discord servers found. Make sure you have:</p>
                  <ul>
                    <li>Authorized DiscordDB with your Discord account</li>
                    <li>Admin or moderator access to the servers you want to manage</li>
                    <li>Invited the DiscordDB bot to your servers</li>
                  </ul>
                  <a href="/app/login" className="reconnect-button">Reconnect Discord</a>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <h2>Set Your Timezone</h2>
            <p>This helps us show you accurate activity times for your servers</p>
            
            <div className="timezone-selection">
              <select 
                value={selectedTimezone}
                onChange={(e) => setSelectedTimezone(e.target.value)}
                className="timezone-select"
              >
                {Object.entries(timezoneGroups).map(([region, zones]) => (
                  <optgroup key={region} label={region}>
                    {zones.map(zone => (
                      <option key={zone} value={zone}>
                        {zone.replace('_', ' ')} ({new Date().toLocaleTimeString('en-US', { timeZone: zone })})
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </div>
          </>
        )}

        <div className="modal-actions">
          {step === 'timezone' && (
            <button 
              className="back-button"
              onClick={handleBack}
            >
              Back
            </button>
          )}
          <button 
            className="continue-button"
            disabled={step === 'servers' && selectedServers.length === 0}
            onClick={handleNext}
          >
            {step === 'servers' 
              ? `Continue with ${selectedServers.length} ${selectedServers.length === 1 ? 'server' : 'servers'}`
              : 'Complete Setup'
            }
          </button>
        </div>
      </div>
    </div>
  );
}

export default ServerSelectionModal; 