import React, { useEffect } from 'react';
import './TabNavigation.css';
import { useServer } from '../contexts/ServerContext.js';

const TabNavigation = ({ activeTab, setActiveTab }) => {
  const { accounts, activeAccount, switchAccount, availableServers } = useServer();

  useEffect(() => {
    // If we have accounts but no active account, set the first one as active
    if (accounts.length > 0 && !activeAccount) {
      switchAccount(accounts[0].id);
    }
  }, [accounts, activeAccount, switchAccount]);

  const tabs = [
    { id: 'Dashboard', icon: '📊' },
    { id: 'Analytics', icon: '📈' },
    { id: 'Documents', icon: '📄' }
    // Settings and Rules tabs are hidden but their content is still accessible
  ];

  const handleLogout = () => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user');
    window.location.href = '/';
  };

  // Function to format server name with proper spacing for the status indicator
  const formatServerName = (name) => {
    if (!name) return 'Unknown Server';
    
    // Check if the first character is the blue dot indicator
    if (name.charAt(0) === '🔵' || name.charAt(0) === '•' || name.charAt(0) === '●') {
      return (
        <>
          <span className="status-indicator">{name.charAt(0)}</span>
          <span className="server-name">{name.substring(1)}</span>
        </>
      );
    }
    
    return name;
  };

  return (
    <div className="tab-navigation">
      <div className="tab-container">
        <div className="nav-left">
          {accounts.length > 0 && (
            <div className="server-select-wrapper">
              <select 
                value={activeAccount || ''} 
                onChange={(e) => switchAccount(Number(e.target.value))}
                className="server-select"
              >
                {accounts.map(account => (
                  <option key={account.id} value={account.id}>
                    {formatServerName(account.server_name)}
                  </option>
                ))}
              </select>
              <div className="server-select-icon">🔍</div>
            </div>
          )}
        </div>

        <div className="nav-center">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => setActiveTab(tab.id)}
            >
              <span className="tab-icon">{tab.icon}</span>
              <span className="tab-label">{tab.id}</span>
            </button>
          ))}
        </div>

        <div className="nav-right">
          <button className="tab-item account" onClick={() => setActiveTab('Settings')}>
            <span className="tab-icon">👤</span>
            <span className="tab-label">Account</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TabNavigation; 