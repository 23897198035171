import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const ServerAccessContext = createContext();

export function useServerAccess() {
  return useContext(ServerAccessContext);
}

export function ServerAccessProvider({ children }) {
  const [moderators, setModerators] = useState([]);
  const [purchasedSeats, setPurchasedSeats] = useState(0);
  const [availableSeats, setAvailableSeats] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchServerAccess = async (serverId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://xqx2-ksev-bf5k.n7.xano.io/api:wxo1ma9h/server/access/${serverId}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
          }
        }
      );
      
      setModerators(response.data.moderators || []);
      setPurchasedSeats(response.data.purchased_seats || 0);
      setAvailableSeats(response.data.available_seats || 0);
      setError(null);
    } catch (err) {
      setError('Failed to fetch server access information');
      console.error('Error fetching server access:', err);
    } finally {
      setLoading(false);
    }
  };

  const addModerator = async (serverId, moderatorId) => {
    try {
      if (availableSeats <= 0) {
        throw new Error('No available seats');
      }

      const response = await axios.post(
        `https://xqx2-ksev-bf5k.n7.xano.io/api:wxo1ma9h/server/moderator`,
        {
          server_id: serverId,
          moderator_id: moderatorId
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
          }
        }
      );

      // Refresh server access data
      await fetchServerAccess(serverId);
      return response.data;
    } catch (err) {
      setError('Failed to add moderator');
      console.error('Error adding moderator:', err);
      throw err;
    }
  };

  const removeModerator = async (serverId, moderatorId) => {
    try {
      await axios.delete(
        `https://xqx2-ksev-bf5k.n7.xano.io/api:wxo1ma9h/server/moderator`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
          },
          data: {
            server_id: serverId,
            moderator_id: moderatorId
          }
        }
      );

      // Refresh server access data
      await fetchServerAccess(serverId);
    } catch (err) {
      setError('Failed to remove moderator');
      console.error('Error removing moderator:', err);
      throw err;
    }
  };

  const purchaseSeats = async (serverId, quantity) => {
    try {
      const response = await axios.post(
        `https://xqx2-ksev-bf5k.n7.xano.io/api:wxo1ma9h/server/seats/purchase`,
        {
          server_id: serverId,
          quantity: quantity
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
          }
        }
      );

      // Refresh server access data
      await fetchServerAccess(serverId);
      return response.data;
    } catch (err) {
      setError('Failed to purchase seats');
      console.error('Error purchasing seats:', err);
      throw err;
    }
  };

  const value = {
    moderators,
    purchasedSeats,
    availableSeats,
    loading,
    error,
    fetchServerAccess,
    addModerator,
    removeModerator,
    purchaseSeats
  };

  return (
    <ServerAccessContext.Provider value={value}>
      {children}
    </ServerAccessContext.Provider>
  );
} 