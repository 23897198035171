import React from 'react';
import SubscriptionPlans from '../../components/SubscriptionPlans.js';
import './SubscriptionSelector.css';

const SubscriptionSelector = ({ onComplete }) => {
  // Get user data from local storage
  const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  if (!userData) {
    return null; // Don't show anything if user isn't logged in
  }

  return (
    <div className="subscription-selector-modal">
      <div className="subscription-selector">
        <button className="close-button" onClick={() => onComplete?.()} aria-label="Close">✕</button>
        
        <div className="subscription-header">
          <h2>Choose Your Plan</h2>
          <p>Select a plan that fits your community</p>
        </div>

        <SubscriptionPlans 
          currentPlan={userData.subscription_plan} 
          onSubscribe={() => onComplete?.()} 
        />
      </div>
    </div>
  );
};

export default SubscriptionSelector; 