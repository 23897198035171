import React, { useState, useEffect } from 'react';
import './Analytics.css';
import TabHeader from './TabHeader.js';
import { useServer } from '../contexts/ServerContext.js';
import { handleApiCall } from '../utils/api.js';
import ThreadPanel from './ThreadPanel.js';

// First, add these activity type color definitions at the top of the file
const ACTIVITY_COLORS = {
  message_create: '#4ade80',  // Light green
  message_reply: '#60a5fa',   // Light blue
  reaction: '#fbbf24',        // Light yellow
  voice_join: '#a78bfa',      // Light purple
  voice_leave: '#a78bfa',     // Light purple
  voice_move: '#a78bfa',      // Light purple
  member_join: '#34d399',     // Light emerald
  member_leave: '#f87171',    // Light red
};

// Improved sentiment aggregation
const aggregateSentimentData = (sentimentArray) => {
  if (!sentimentArray || !Array.isArray(sentimentArray)) return {};

  const aggregated = {};
  let totalCount = 0;

  sentimentArray.forEach(channelData => {
    // Get data from sentimentAnalysis.frequencyMap
    if (channelData?.sentimentAnalysis?.frequencyMap) {
      Object.entries(channelData.sentimentAnalysis.frequencyMap).forEach(([sentiment, count]) => {
        const normalizedSentiment = sentiment.toLowerCase();
        aggregated[normalizedSentiment] = aggregated[normalizedSentiment] || { count: 0, percentage: 0 };
        aggregated[normalizedSentiment].count += count;
        totalCount += count;
      });
    }

    // Also consider metrics.sentiment data if available
    if (channelData?.metrics?.sentiment?.percentages) {
      const { positive, negative, neutral } = channelData.metrics.sentiment.percentages;
      const messageCount = channelData.metrics.summary.message_count || 0;
      
      // Convert percentages back to counts and add them
      if (positive) {
        aggregated.positive = aggregated.positive || { count: 0, percentage: 0 };
        aggregated.positive.count += Math.round((parseFloat(positive) / 100) * messageCount);
      }
      if (negative) {
        aggregated.negative = aggregated.negative || { count: 0, percentage: 0 };
        aggregated.negative.count += Math.round((parseFloat(negative) / 100) * messageCount);
      }
      if (neutral) {
        aggregated.neutral = aggregated.neutral || { count: 0, percentage: 0 };
        aggregated.neutral.count += Math.round((parseFloat(neutral) / 100) * messageCount);
      }
    }

    // Process thread sentiments
    channelData.channel_threads?.forEach(thread => {
      const messageCount = thread.message_count || 0;
      if (messageCount > 0) {
        // Get sentiment percentages from the thread's metrics if available
        const threadSentiment = thread.metrics?.sentiment?.percentages || {};
        const normalizedSentiment = thread.sentiment?.toLowerCase().replace('very ', '').replace('somewhat ', '') || 'neutral';
        
        // Update aggregated counts based on thread sentiment
        if (threadSentiment.positive) {
          aggregated.positive = aggregated.positive || { count: 0, percentage: 0 };
          aggregated.positive.count += Math.round((parseFloat(threadSentiment.positive) / 100) * messageCount);
        } else if (normalizedSentiment === 'positive') {
          aggregated.positive = aggregated.positive || { count: 0, percentage: 0 };
          aggregated.positive.count += messageCount;
        }
        
        if (threadSentiment.negative) {
          aggregated.negative = aggregated.negative || { count: 0, percentage: 0 };
          aggregated.negative.count += Math.round((parseFloat(threadSentiment.negative) / 100) * messageCount);
        } else if (normalizedSentiment === 'negative') {
          aggregated.negative = aggregated.negative || { count: 0, percentage: 0 };
          aggregated.negative.count += messageCount;
        }
        
        if (threadSentiment.neutral) {
          aggregated.neutral = aggregated.neutral || { count: 0, percentage: 0 };
          aggregated.neutral.count += Math.round((parseFloat(threadSentiment.neutral) / 100) * messageCount);
        } else if (normalizedSentiment === 'neutral') {
          aggregated.neutral = aggregated.neutral || { count: 0, percentage: 0 };
          aggregated.neutral.count += messageCount;
        }
        
        totalCount += messageCount;
      }
    });
  });

  // Recalculate total count
  totalCount = Object.values(aggregated).reduce((sum, { count }) => sum + count, 0);

  // Calculate final percentages
  Object.values(aggregated).forEach(data => {
    data.percentage = totalCount > 0 ? (data.count / totalCount) * 100 : 0;
  });

  return aggregated;
};

const getMostCommonSentiment = (sentimentArray) => {
  if (!sentimentArray || !Array.isArray(sentimentArray)) return 'neutral';

  const aggregated = aggregateSentimentData(sentimentArray);
  const sortedSentiments = Object.entries(aggregated)
    .sort((a, b) => b[1].percentage - a[1].percentage);
  
  // Add qualifiers based on percentage thresholds
  const [topSentiment, data] = sortedSentiments[0] || ['neutral', { percentage: 0 }];
  if (data.percentage > 60) return `very ${topSentiment}`;
  if (data.percentage > 40) return `somewhat ${topSentiment}`;
  return topSentiment;
};

// Improved topic aggregation
const getTopTopics = (sentimentArray) => {
  if (!sentimentArray || !Array.isArray(sentimentArray)) return [];

  const topicMap = new Map();

  sentimentArray.forEach(channelData => {
    // Process topics array
    channelData.topics?.forEach(topic => {
      if (!topicMap.has(topic.name)) {
        topicMap.set(topic.name, {
          name: topic.name,
          summary: topic.summary,
          keyTerms: topic.key_terms,
          messageCount: 0,
          channels: new Set(),
          sentiment: {
            positive: { count: 0, percentage: 0 },
            negative: { count: 0, percentage: 0 },
            neutral: { count: 0, percentage: 0 }
          }
        });
      }

      // Count messages from threads related to this topic
      channelData.channel_threads?.forEach(thread => {
        if (thread.topic === topic.name) {
          const topicData = topicMap.get(topic.name);
          const threadMessageCount = thread.message_count || 0;
          topicData.messageCount += threadMessageCount;
          topicData.channels.add(channelData.name);
          
          // Convert sentiment to lowercase and normalize
          const normalizedSentiment = thread.sentiment.toLowerCase().replace('very ', '').replace('somewhat ', '');
          if (topicData.sentiment[normalizedSentiment]) {
            topicData.sentiment[normalizedSentiment].count += threadMessageCount;
          } else {
            // Default to neutral if sentiment is not recognized
            topicData.sentiment.neutral.count += threadMessageCount;
          }
        }
      });
    });

    // Also check metrics.summary.top_topics if available
    channelData.metrics?.summary?.top_topics?.forEach(topicStr => {
      const [name, percentage] = topicStr.match(/(.+) \((\d+)%\)/)?.slice(1) || [];
      if (name && !topicMap.has(name)) {
        topicMap.set(name, {
          name,
          percentage: parseFloat(percentage),
          messageCount: 0,
          channels: new Set([channelData.name]),
          sentiment: {
            positive: { count: 0, percentage: 0 },
            negative: { count: 0, percentage: 0 },
            neutral: { count: 0, percentage: 0 }
          }
        });
      }
    });
  });

  // Calculate sentiment percentages for each topic
  for (const topic of topicMap.values()) {
    const totalMessages = topic.messageCount;
    if (totalMessages > 0) {
      Object.keys(topic.sentiment).forEach(sentiment => {
        topic.sentiment[sentiment].percentage = 
          (topic.sentiment[sentiment].count / totalMessages) * 100;
      });
    }
  }

  // Convert to array and sort by message count
  return Array.from(topicMap.values())
    .sort((a, b) => b.messageCount - a.messageCount)
    .map(topic => ({
      ...topic,
      channels: Array.from(topic.channels)
    }));
};

const getSentimentColor = (sentiment) => {
  const colors = {
    positive: '#4CAF50',
    negative: '#F44336',
    neutral: '#9E9E9E',
    'very positive': '#2E7D32',
    'somewhat negative': '#FF7043',
    'very negative': '#D32F2F'
  };
  return colors[sentiment.toLowerCase()] || '#9E9E9E';
};

// Add this helper function to group activities by hour
const groupActivitiesByHour = (activities) => {
  const hourMap = {};
  
  // Initialize all hours with empty data
  for (let i = 0; i < 24; i++) {
    hourMap[i] = {
      hour: i,
      total: 0,
      activities: {
        message_create: 0,
        message_reply: 0,
        reaction: 0,
        voice_join: 0,
        voice_leave: 0,
        voice_move: 0,
        member_join: 0,
        member_leave: 0,
      },
      emojis: 0
    };
  }

  // Aggregate activities
  if (activities && Array.isArray(activities)) {
    activities.forEach(activity => {
      if (activity && typeof activity.hour === 'number') {
        hourMap[activity.hour].total += activity.messages_count || 0;
        if (activity.type && hourMap[activity.hour].activities[activity.type] !== undefined) {
          hourMap[activity.hour].activities[activity.type] += activity.messages_count || 0;
        }
        if (activity.emojis) {
          hourMap[activity.hour].emojis += activity.emojis;
        }
      }
    });
  }

  return hourMap;
};

function Analytics() {
  const { activeAccount, accounts } = useServer();
  const [dateRange, setDateRange] = useState('24h');
  const [overview, setOverview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedThread, setSelectedThread] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState('All');

  const handleThreadClick = async (threadId) => {
    try {
      const data = await handleApiCall(
        `https://xqx2-ksev-bf5k.n7.xano.io/api:HZtICWLN/server/overview/thread/${threadId}`
      );
      if (data) {
        setSelectedThread(data);
      }
    } catch (error) {
      console.error('Error fetching thread details:', error);
    }
  };

  const getDateRange = (range) => {
    // Get current date and set to midnight of next day for end date
    const today = new Date();
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0, 0);
    
    // Adjust for timezone offset
    const tzOffset = endDate.getTimezoneOffset() * 60000; // convert to milliseconds
    const endDateUTC = new Date(endDate.getTime() - tzOffset);

    // For 'all' duration, return null start date
    if (range === 'all') {
      return { start: null, end: endDateUTC.toISOString() };
    }
    
    // Calculate start date based on range
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
    
    switch (range) {
      case 'today':
        // Start date is already set to midnight of current day
        break;
      case '24h':
        // Go back exactly 24 hours from end date
        startDate.setDate(startDate.getDate() - 1);
        break;
      case '7d':
        // Go back 7 days from current day
        startDate.setDate(startDate.getDate() - 7);
        break;
      case '30d':
        // Go back 30 days from current day
        startDate.setDate(startDate.getDate() - 30);
        break;
      default:
        // Default to today
        break;
    }

    // Adjust start date for timezone offset
    const startDateUTC = new Date(startDate.getTime() - tzOffset);
    
    return { 
      start: startDateUTC.toISOString(),
      end: endDateUTC.toISOString()
    };
  };

  useEffect(() => {
    const fetchServerOverview = async () => {
      if (!activeAccount) return;

      const account = accounts.find(acc => acc.id === activeAccount);
      if (!account?.discord_server_id) return;

      setIsLoading(true);
      try {
        const { start, end } = getDateRange(dateRange);
        const params = {
          discord_server_id: account.discord_server_id,
          end_date: end
        };

        // Only add start_date to params if it's not null and not 'all' range
        if (start !== null && dateRange !== 'all') {
          params.start_date = start;
        }

        const queryString = new URLSearchParams(params).toString();
        const data = await handleApiCall(
          `https://xqx2-ksev-bf5k.n7.xano.io/api:HZtICWLN/server/overview?${queryString}`
        );

        if (data) {
          console.log('Server overview data:', data);
          
          // Initialize with empty arrays/objects if data is missing
          const processedData = {
            ...data,
            hourlyActivity: data.peakActivityRecords?.length ? data.peakActivityRecords.sort((a, b) => a.hour - b.hour) :
                           data.peakAcitivtyRecords?.length ? data.peakAcitivtyRecords.sort((a, b) => a.hour - b.hour) :
                           data.hourlyActivity?.length ? data.hourlyActivity.sort((a, b) => a.hour - b.hour) : [],
            sentiment: data.sentiment || [],
            peakTimeStats: {
              peakActivityTime: null,
              quietestTime: null,
              highestEngagement: null
            }
          };
          
          // Only calculate stats if we have hourly activity data
          if (processedData.hourlyActivity?.length) {
            processedData.peakTimeStats = {
              peakActivityTime: processedData.hourlyActivity.reduce((max, hour) => 
                hour.messages_count > (max?.messages_count || 0) ? hour : max, null),
              quietestTime: processedData.hourlyActivity.reduce((min, hour) => 
                (hour.messages_count < (min?.messages_count || Infinity) && hour.messages_count > 0) ? hour : min, null),
              highestEngagement: processedData.hourlyActivity.reduce((max, hour) => {
                const totalEngagement = hour.messages_count + (hour.emojis || 0);
                return totalEngagement > (max?.totalEngagement || 0) 
                  ? { ...hour, totalEngagement } 
                  : max;
              }, null)
            };
          }
          
          setOverview(processedData);
        }
      } catch (error) {
        console.error('Error fetching server overview:', error);
        setOverview({});
      } finally {
        setIsLoading(false);
      }
    };

    fetchServerOverview();
  }, [activeAccount, accounts, dateRange]);

  // Helper function to format hour display
  const formatHourDisplay = (hour) => {
    if (hour === null || hour === undefined) return 'Unknown';
    if (hour === 0) return '12am';
    if (hour === 12) return '12pm';
    return hour > 12 ? `${hour-12}pm` : `${hour}am`;
  };

  const LoadingSpinner = () => (
    <div className="app-spinner"></div>
  );

  const LoadingCard = () => (
    <div className="loading-card">
      <LoadingSpinner />
    </div>
  );

  // Extract unique channel names from threads
  const channelNames = Array.from(new Set(overview?.sentiment?.flatMap(channelData => 
    channelData?.channel_threads?.map(thread => channelData.name).filter(Boolean) || []
  )));

  // Add a function to handle channel selection
  const handleChannelSelect = (channel) => {
    setSelectedChannel(channel);
  };

  // Filter threads based on selected channel
  const filteredThreads = overview?.sentiment?.flatMap(channelData => {
    if (!channelData?.channel_threads) return [];
    
    if (selectedChannel === 'All' || channelData.name === selectedChannel) {
      return channelData.channel_threads.map(thread => ({
        ...thread,
        channelName: channelData.name || 'unknown'
      }));
    }
    return [];
  }).filter(Boolean) || [];

  // Improved insight generation
  const getOverallSentimentInsight = (sentimentArray) => {
    if (!sentimentArray || !Array.isArray(sentimentArray)) return 'No sentiment data available';

    const insights = [];
    
    // Collect all available insights
    sentimentArray.forEach(channelData => {
      if (channelData?.metrics?.summary?.primary_insight) {
        insights.push(channelData.metrics.summary.primary_insight);
      }
      
      // Add insights about topic diversity
      if (channelData.topics?.length > 0) {
        insights.push(`${channelData.topics.length} distinct topics discussed`);
      }
      
      // Add insights about sentiment distribution
      if (channelData.metrics?.sentiment?.percentages) {
        const { positive, negative } = channelData.metrics.sentiment.percentages;
        if (positive > 50) {
          insights.push('Predominantly positive discussions');
        } else if (negative > 30) {
          insights.push('Significant negative sentiment detected');
        }
      }
    });

    return insights.length > 0 ? insights[0] : 'No insights available';
  };

  return (
    <div className="analytics">
      <TabHeader 
        tab="Analytics"
        dateRange={dateRange}
        onDateRangeChange={setDateRange}
        showDateRange={true}
        showInsightsButton={false}
      />

      <div className="analytics-grid">
        {/* Server Overview - Full Width */}
        <div className="analytics-card highlight full-width">
          <h3>Server Overview</h3>
          {isLoading ? (
            <div className="overview-grid loading">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="overview-grid">
              <div className="historical-label">Historical Data</div>
              <div className="dynamic-label">Date Adjusted</div>
              
              <div className="overview-grid-group historical">
                <div className="overview-stat">
                  <div className="stat-header">
                    <span className="stat-value">{overview?.totalMembers?.toLocaleString() || '0'}</span>
                    <div className="info-tooltip" title="Based on Discord Historical Records 📜">ⓘ</div>
                  </div>
                  <span className="stat-label">Total Members</span>
                  <span className="stat-trend">
                    <span className="positive">+{overview?.joinedThisWeek || '0'} joined</span>, <span className={overview?.leftThisWeek > 0 ? 'negative' : ''}>{overview?.leftThisWeek || '0'} left</span> this period
                  </span>
                </div>
                <div className="overview-stat">
                  <div className="stat-header">
                    <span className="stat-value">{overview?.activeMemberCount?.toLocaleString() || '0'}</span>
                    <div className="info-tooltip" title="Based on Discord Historical Records 📜">ⓘ</div>
                  </div>
                  <span className="stat-label">Active Members</span>
                  <span className={`stat-trend ${overview?.percentOfActiveMembers >= 50 ? 'positive' : 'negative'}`}>
                    {overview?.percentOfActiveMembers || '0'}% of total
                  </span>
                </div>
              </div>

              <div className="overview-grid-group dynamic">
                <div className="overview-stat">
                  <span className="stat-value">{overview?.dailyMessages?.toLocaleString() || '0'}</span>
                  <span className="stat-label">Daily Messages</span>
                  <span className={`stat-trend ${overview?.messagesPerUser >= 10 ? 'positive' : 'negative'}`}>
                    {overview?.messagesPerUser?.toFixed(1) || '0'} per user
                  </span>
                </div>
                {/* Commenting out user retention section as requested
                <div className="overview-stat">
                  <div className="stat-header">
                    <span className="stat-value">
                      {overview?.textOldMonthActive === 0 
                        ? 'N/A' 
                        : `${((overview?.newActive / overview?.textOldMonthActive) * 100).toFixed(1)}%`
                      }
                    </span>
                    <div className="info-tooltip" title="Percentage of previously active members who remained active in this period">ⓘ</div>
                  </div>
                  <span className="stat-label">Active User Retention</span>
                  <span className={`stat-trend ${overview?.newActive >= overview?.textOldMonthActive ? 'positive' : 'negative'}`}>
                    {overview?.textOldMonthActive === 0 
                      ? 'First period - no previous data'
                      : `${overview?.newActive || '0'} of ${overview?.textOldMonthActive || '0'} users retained`
                    }
                  </span>
                </div>
                */}
                <div className="overview-stat">
                  <div className="stat-header">
                    <span className="stat-value">
                      {overview?.voice?.new ? 
                        (() => {
                          const totalMinutes = Math.floor((Object.values(overview.voice.new).reduce((a,b) => a+b, 0) / 1000 / 60));
                          const hours = Math.floor(totalMinutes / 60);
                          const minutes = totalMinutes % 60;
                          return `${hours}h ${minutes}m`;
                        })() : 
                        '0h 0m'
                      }
                    </span>
                    <div className="info-tooltip" title="Total time spent in voice channels">ⓘ</div>
                  </div>
                  <span className="stat-label">Voice Time</span>
                  <span className={`stat-trend ${
                    (Object.values(overview?.voice?.new || {}).reduce((a,b) => a+b, 0) >= 
                     Object.values(overview?.voice?.old || {}).reduce((a,b) => a+b, 0)) 
                    ? 'positive' : 'negative'}`}>
                    {overview?.voice?.old ? 
                      (() => {
                        const totalMinutes = Math.floor((Object.values(overview.voice.old).reduce((a,b) => a+b, 0) / 1000 / 60));
                        const hours = Math.floor(totalMinutes / 60);
                        const minutes = totalMinutes % 60;
                        return `vs ${hours}h ${minutes}m last period`;
                      })() :
                      'No previous data'
                    }
                  </span>
                </div>
                {/* Adding Q&A overview stat to highlight it more */}
                <div className="overview-stat">
                  <div className="stat-header">
                    <span className="stat-value">{overview?.asked || 0}</span>
                    <div className="info-tooltip" title="Total number of questions asked to the Q&A system">ⓘ</div>
                  </div>
                  <span className="stat-label">Q&A Queries</span>
                  <span className={`stat-trend positive`}>
                    {overview?.asked ? `${overview.asked}% accuracy` : 'No data'}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Activity Heatmap - Wide */}
        <div className="analytics-card wide">
          <div className="card-header">
            <h3>Peak Activity Times</h3>
            <div className="card-actions">

            </div>
          </div>
          {isLoading ? (
            <LoadingCard />
          ) : (
            <>
              <div className="activity-summary">
                <div className="summary-stat">
                  <div className="summary-icon">⏱️</div>
                  <span className="summary-value">
                    {overview?.peakTimeStats?.peakActivityTime?.hour !== undefined 
                      ? formatHourDisplay(overview.peakTimeStats.peakActivityTime.hour)
                      : 'No data'}
                  </span>
                  <span className="summary-label">Peak Activity Time</span>
                  {overview?.peakTimeStats?.peakActivityTime && (
                    <span className="stat-detail">{overview.peakTimeStats.peakActivityTime.messages_count} messages</span>
                  )}
                </div>
                <div className="summary-stat">
                  <div className="summary-icon">🔥</div>
                  <span className="summary-value">
                    {overview?.peakTimeStats?.highestEngagement?.hour !== undefined 
                      ? formatHourDisplay(overview.peakTimeStats.highestEngagement.hour)
                      : 'No data'}
                  </span>
                  <span className="summary-label">Highest Engagement</span>
                  {overview?.peakTimeStats?.highestEngagement && (
                    <span className="stat-detail">{overview.peakTimeStats.highestEngagement.totalEngagement} total engagement</span>
                  )}
                </div>
                <div className="summary-stat">
                  <div className="summary-icon">🌙</div>
                  <span className="summary-value">
                    {overview?.peakTimeStats?.quietestTime?.hour !== undefined 
                      ? formatHourDisplay(overview.peakTimeStats.quietestTime.hour)
                      : 'No data'}
                  </span>
                  <span className="summary-label">Quietest Time</span>
                  {overview?.peakTimeStats?.quietestTime && (
                    <span className="stat-detail">{overview.peakTimeStats.quietestTime.messages_count} messages</span>
                  )}
                </div>
              </div>

              <div className="github-style-heatmap">
                {isLoading ? (
                  <LoadingCard />
                ) : (
                  <>
                    <div className="heatmap-header">
                      <h4>24-Hour Activity Distribution</h4>
                      <div className="heatmap-legend">
                        <div className="legend-label">Less</div>
                        <div className="legend-cells">
                          {[0, 1, 2, 3, 4].map((level) => (
                            <div 
                              key={level} 
                              className="legend-cell"
                              style={{
                                backgroundColor: `rgba(114, 137, 218, ${level * 0.2 + 0.1})` // Same Discord blue with varying opacity
                              }}
                            />
                          ))}
                        </div>
                        <div className="legend-label">More</div>
                      </div>
                    </div>
                    
                    <div className="heatmap-container">
                      <div className="heatmap-hours">
                        {Array.from({ length: 24 }, (_, i) => (
                          <div key={i} className="hour-label">
                            {i === 0 ? '12am' : i === 12 ? '12pm' : i > 12 ? `${i-12}pm` : `${i}am`}
                          </div>
                        ))}
                      </div>
                      
                      <div className="heatmap-grid">
                        {Array.from({ length: 24 }, (_, i) => {
                          const hourlyData = groupActivitiesByHour(overview?.hourlyActivity);
                          const hourData = hourlyData[i] || { total: 0, activities: {} };
                          
                          // Safely calculate the max total with fallback to 1 to avoid division by zero
                          const maxTotal = Math.max(
                            1, 
                            ...Object.values(hourlyData).map(h => h.total || 0)
                          );
                          
                          const opacity = hourData.total > 0 ? (hourData.total / maxTotal) * 0.8 + 0.1 : 0;
                          
                          // Determine if this is a peak hour
                          const isPeakHour = overview?.peakTimeStats?.peakActivityTime?.hour === i;
                          const isQuietestHour = overview?.peakTimeStats?.quietestTime?.hour === i && hourData.total > 0;
                          
                          return (
                            <div 
                              key={i} 
                              className={`heatmap-cell ${isPeakHour ? 'peak-hour' : ''} ${isQuietestHour ? 'quietest-hour' : ''}`}
                              style={{
                                backgroundColor: `rgba(114, 137, 218, ${opacity})`, // Discord blue
                              }}
                            >
                              {isPeakHour && <div className="peak-indicator">⭐</div>}
                              
                              <div className="cell-tooltip">
                                <div className="tooltip-time">
                                  {formatHourDisplay(i)}
                                  {isPeakHour && <span className="peak-label">Peak</span>}
                                </div>
                                {Object.entries(hourData.activities || {})
                                  .filter(([_, count]) => count > 0)
                                  .map(([type, count]) => (
                                    <div key={type} className="tooltip-activity">
                                      <span className="activity-label">
                                        {type
                                          .split('_')
                                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                          .join(' ')
                                        }: </span>
                                      <span className="activity-count">{count}</span>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>

        {/* Active Channels Card */}
        <div className="analytics-card full-width">
          <div className="card-header">
            <h3>Active Channels</h3>
          </div>
          <div className="active-channels-container">
            {overview?.activeChannels?.items.map((item) => (
              <div key={item.channel_id} className="channel-card">
                <div className="channel-header">
                  <span className="channel-name">#{item.channel.name}</span>
                  <span className="channel-trend positive">
                    {item.count} messages
                  </span>
                </div>
                <div className="channel-stats">
                  <div className="stat-group">
                    <span className="group-value">{item.count}</span>
                    <span className="group-label">messages today</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Q&A Performance */}
        <div className="analytics-card">
          <div className="card-header">
            <h3>Q&A Performance</h3>
          </div>
          <div className="performance-metrics">
            <div className="metric-row">
              <div className="metric-item">
                <span className="metric-value">{overview?.asked || 'No data'}</span>
                <span className="metric-label">Queries Today</span>
              </div>
              <div className="metric-item">
                <span className="metric-value">{overview?.noResultRate ? `${overview.noResultRate}%` : 'No data'}</span>
                <span className="metric-label">No Results</span>
              </div>
              <div className="metric-item">
                <span className="metric-value">{overview?.avgResponseTime || 'No data'}</span>
                <span className="metric-label">Avg Response</span>
              </div>
            </div>
            <div className="top-queries">
              <h4>Top Questions</h4>
              {overview?.topQueries?.length ? (
                overview.topQueries.map((query, index) => (
                  <div key={index} className="query-item">
                    <div className="query-info">
                      <span className="query-text">{query.query}</span>
                      <div className="query-metrics">
                        <span>{query.frequency} times</span>
                        <span className="accuracy">{query.accuracy}% accurate</span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-data">No questions data available</div>
              )}
            </div>
            
            <div className="knowledge-gaps">
              <h4>Knowledge Gaps</h4>
              <div className="gaps-container">
                {overview?.knowledgeGaps?.length ? (
                  overview.knowledgeGaps.map((gap, index) => (
                    <div key={index} className={`gap-item ${gap.priority}`}>
                      <div className="gap-info">
                        <span className="gap-topic">{gap.topic}</span>
                        <div className="gap-metrics">
                          <span className="gap-impact">{gap.impact} queries affected</span>
                          <span className={`gap-priority ${gap.priority}`}>{gap.priority} priority</span>
                        </div>
                      </div>
                      <div className="gap-action">
                        <button className="gap-action-btn">Add Content</button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-data">No knowledge gaps data available</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Message Threads */}
        <div className="analytics-card">
          <div className="card-header">
            <h3>Message Threads</h3>
            <div className="card-actions">
              <span className="thread-count">
                {filteredThreads.length || 0} threads
              </span>
            </div>
          </div>
          <div className="thread-metrics">
            <div className="channel-filter">
              <label htmlFor="channel-select">Filter by Channel:</label>
              <select id="channel-select" value={selectedChannel} onChange={(e) => handleChannelSelect(e.target.value)}>
                <option value="All">All Channels</option>
                {channelNames.map((name, index) => (
                  <option key={index} value={name}>{name}</option>
                ))}
              </select>
            </div>
            <div className="thread-list">
              {filteredThreads.map((thread, index) => (
                <div 
                  key={thread.id || index} 
                  className={`thread-item ${thread.sentiment || 'neutral'}`} 
                  onClick={() => handleThreadClick(thread.id)}
                  style={{
                    marginBottom: '12px',
                    padding: '12px',
                    borderRadius: '8px',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                    }
                  }}
                >
                  <div className="thread-content">
                    <div className="thread-header">
                      <span className="thread-title">{thread.topic || 'Untitled Thread'}</span>
                      <span className={`thread-sentiment ${thread.sentiment || 'neutral'}`}>
                        {(thread.sentiment || 'neutral').charAt(0).toUpperCase() + 
                         (thread.sentiment || 'neutral').slice(1)}
                      </span>
                    </div>
                    <div className="thread-progress">
                      <div 
                        className="thread-progress-bar"
                        style={{ width: `${(thread.message_count / Math.max(...filteredThreads.map(t => t.message_count || 0))) * 100}%` }}
                      ></div>
                      <span className="thread-progress-label">
                        {thread.message_count || 0} messages
                      </span>
                    </div>
                  </div>
                  <div className="thread-meta">
                    <div className="thread-date">
                      {thread.day ? new Date(thread.day).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric'
                      }) : 'No date'}
                    </div>
                    <div className="thread-channel">
                      #{(thread.channelName || 'unknown').toLowerCase().replace(/_/g, '-')}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Community Sentiment - Full Width */}
        <div className="analytics-card full-width">
          <div className="card-header">
            <h3>Community Sentiment</h3>
            <div className="card-actions">
              {overview?.sentiment ? (
                <span className={`sentiment-score ${getMostCommonSentiment(overview.sentiment) || 'neutral'}`}>
                  {(getMostCommonSentiment(overview.sentiment) || 'neutral').charAt(0).toUpperCase() + 
                   (getMostCommonSentiment(overview.sentiment) || 'neutral').slice(1)}
                </span>
              ) : (
                <span className="sentiment-score neutral">Neutral</span>
              )}
            </div>
          </div>
          <div className="sentiment-metrics">
            {isLoading ? (
              <div className="sentiment-loading">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="sentiment-overview">
                <div className="sentiment-chart">
                  {overview?.sentiment ? (
                    <>
                      <div className="percentage-labels">
                        <div className="percentage-label">100%</div>
                        <div className="percentage-label">90%</div>
                        <div className="percentage-label">80%</div>
                        <div className="percentage-label">70%</div>
                        <div className="percentage-label">60%</div>
                        <div className="percentage-label">50%</div>
                        <div className="percentage-label">40%</div>
                        <div className="percentage-label">30%</div>
                        <div className="percentage-label">20%</div>
                        <div className="percentage-label">10%</div>
                        <div className="percentage-label">0%</div>
                      </div>
                      <div className="sentiment-columns">
                        {Object.entries(aggregateSentimentData(overview.sentiment))
                          .sort((a, b) => b[1].count - a[1].count)
                          .map(([sentiment, data]) => {
                            // Get channel-specific breakdown
                            const channelBreakdown = overview.sentiment.reduce((acc, channel) => {
                              if (channel?.metrics?.sentiment?.percentages) {
                                const percentage = parseFloat(channel.metrics.sentiment.percentages[sentiment.toLowerCase()] || 0);
                                if (percentage > 0) {
                                  acc.push({
                                    name: channel.name,
                                    percentage: percentage,
                                    messageCount: channel.metrics.summary.message_count || 0
                                  });
                                }
                              }
                              return acc;
                            }, []);

                            return (
                              <div key={sentiment} className="sentiment-column">
                                <div className="column-bar-container">
                                  <div 
                                    className={`column-bar ${sentiment.replace(/\s+/g, '-')}`} 
                                    style={{ 
                                      height: data.percentage > 0 ? `${Math.max(1, data.percentage)}%` : '0',
                                      backgroundColor: getSentimentColor(sentiment)
                                    }}
                                  >
                                    <div className="sentiment-tooltip">
                                      <div className="tooltip-header">
                                        {sentiment.charAt(0).toUpperCase() + sentiment.slice(1)}
                                      </div>
                                      <div className="tooltip-total">
                                        {data.count.toLocaleString()} messages ({data.percentage.toFixed(1)}%)
                                      </div>
                                      {channelBreakdown.length > 0 && (
                                        <div className="tooltip-channels">
                                          <div className="tooltip-subtitle">Channel Breakdown:</div>
                                          {channelBreakdown.map((ch, idx) => (
                                            <div key={idx} className="tooltip-channel">
                                              <span className="channel-name">{ch.name}</span>
                                              <span className="channel-stats">
                                                {ch.percentage.toFixed(1)}% ({Math.round((ch.percentage/100) * ch.messageCount)} msgs)
                                              </span>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="sentiment-label">
                                  {sentiment.charAt(0).toUpperCase() + sentiment.slice(1)}<br />
                                  {data.percentage.toFixed(1)}% ({data.count})
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  ) : (
                    <div className="no-data">No sentiment data available</div>
                  )}
                </div>
                
                <div className="sentiment-footer">
                  <div className="trend-item">
                    <span className="trend-icon">📊</span>
                    <div className="trend-details">
                      <span className="trend-text">
                        Overall Tone: {getMostCommonSentiment(overview?.sentiment) || 'Neutral'}
                      </span>
                      <span className="trend-insight">
                        {getOverallSentimentInsight(overview?.sentiment || [])}
                      </span>

                    </div>
                  </div>
                  <div className="top-topics">
                    {getTopTopics(overview?.sentiment || [])?.slice(0, 10).map((topic, index) => (
                      <div key={index} className="topic-item">
                        <span className="topic-icon">🔍</span>
                        <div className="topic-content">
                          <span className="topic-name">{topic.name}</span>
                          <span className="topic-summary">{topic.summary}</span>
                          <div className="topic-metadata">
                            <span>Messages: {topic.messageCount}</span>
                            <span>•</span>
                            <span>Channels: {topic.channels.length}</span>
                            <span>•</span>
                            <span>Sentiment: {
                              Object.entries(topic.sentiment || {})
                                .filter(([, data]) => data.count > 0)
                                .sort(([, a], [, b]) => b.percentage - a.percentage)
                                .map(([type, data]) => 
                                  `${Math.round(data.percentage)}% ${type} (${data.count})`
                                )
                                .join(', ')
                            }</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add the ThreadPanel component outside the grid */}
      {selectedThread && (
        <ThreadPanel 
          thread={selectedThread} 
          onClose={() => setSelectedThread(null)} 
        />
      )}
    </div>
  );
}

export default Analytics;