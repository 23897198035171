import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/components/subscription.css';

// Create axios instance with base URL
const api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' 
    ? 'http://localhost:3000' 
    : 'https://distrag.com',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

const formatPrice = (amount, currency = 'usd') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
  });
  
  return formatter.format(amount / 100);
};

// Default price IDs for subscriptions - these will be overridden by the API
const DEFAULT_PRICES = {
  basicMonthly: '',
  basicAnnual: '',
  proMonthly: '',
  proAnnual: ''
};

const SubscriptionPlans = ({ currentPlan, onSubscribe }) => {
  const [prices, setPrices] = useState(DEFAULT_PRICES);
  const [loading, setLoading] = useState(true);
  const [billingCycle, setBillingCycle] = useState('monthly');

  // Fetch price IDs from the backend
  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await api.get('/api/subscription/prices');
        if (response.data && response.data.prices) {
          setPrices(response.data.prices);
        }
      } catch (error) {
        console.error('Error fetching prices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrices();
  }, []);

  const handleStartTrial = async (plan) => {
    try {
      setLoading(true);

      // First check Xano /me endpoint - same as handleSubscribe
      const meResponse = await axios.get('https://xqx2-ksev-bf5k.n7.xano.io/api:wxo1ma9h/auth/me', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
        }
      });

      const userData = meResponse.data;
      
      // Debug logging
      console.log('User data from Xano:', userData);
      console.log('Making trial request with:', {
        plan: plan,
        metadata: {
          user_id: userData?.id,
          discord_id: userData?.discord_id
        }
      });

      const response = await api.post(
        '/api/subscription/trial',
        {
          plan: plan,
          metadata: {
            user_id: userData?.id,
            discord_id: userData?.discord_id
          }
        }
      );

      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('Invalid trial response');
      }
    } catch (error) {
      console.error('Error starting trial:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      }
      alert('Failed to start trial. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = async (priceId) => {
    try {
      setLoading(true);

      // First check Xano /me endpoint
      const meResponse = await axios.get('https://xqx2-ksev-bf5k.n7.xano.io/api:wxo1ma9h/auth/me', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
        }
      });

      const userData = meResponse.data;
      
      // Debug logging
      console.log('User data from Xano:', userData);
      console.log('Making checkout request with:', {
        price_id: priceId,
        metadata: {
          user_id: userData?.id,
          discord_id: userData?.discord_id
        }
      });

      const response = await api.post(
        '/api/subscription/checkout',
        {
          price_id: priceId,
          metadata: {
            user_id: userData?.id,
            discord_id: userData?.discord_id
          }
        }
      );

      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('Invalid checkout response');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      }
      alert('Failed to start subscription. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="loading">Loading subscription plans...</div>;
  }

  const basicMonthlyPrice = prices.basicMonthly;
  const basicAnnualPrice = prices.basicAnnual;
  const proMonthlyPrice = prices.proMonthly;
  const proAnnualPrice = prices.proAnnual;

  return (
    <div className="subscription-plans">
      <div className="billing-toggle">
        <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
        <label className="switch">
          <input
            type="checkbox"
            checked={billingCycle === 'annual'}
            onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annual' : 'monthly')}
          />
          <span className="slider round"></span>
        </label>
        <span className={billingCycle === 'annual' ? 'active' : ''}>Annual (Save ~17%)</span>
      </div>

      <div className="plans-container">
        <div className="plan-card">
          <div className="plan-header">
            <h3>Basic RAG</h3>
            <div className="price">
              <span className="amount">
                {billingCycle === 'monthly' 
                  ? formatPrice(2000) 
                  : `${formatPrice(20000 / 12)}/mo`}
              </span>
              <span className="period">
                {billingCycle === 'monthly' ? 'per month' : 'billed annually'}
              </span>
            </div>
            <p>AI-powered semantic search for your Discord</p>
          </div>
          <ul className="plan-features">
            <li>Up to 50,000 messages/month</li>
            <li>30-day message history</li>
            <li>Single Discord server</li>
            <li>Basic analytics</li>
            <li>Email support</li>
          </ul>
          {currentPlan === 'basic' ? (
            <button className="plan-cta current">Current Plan</button>
          ) : (
            <>
              <button 
                className="plan-cta" 
                onClick={() => handleSubscribe(billingCycle === 'monthly' ? basicMonthlyPrice : basicAnnualPrice)}
              >
                Subscribe
              </button>
              {!currentPlan && (
                <button 
                  className="plan-cta secondary" 
                  onClick={() => handleStartTrial('basic')}
                >
                  Start 14-Day Free Trial
                </button>
              )}
            </>
          )}
        </div>

        <div className="plan-card popular">
          <div className="popular-tag">Most Popular</div>
          <div className="plan-header">
            <h3>Pro RAG</h3>
            <div className="price">
              <span className="amount">
                {billingCycle === 'monthly' 
                  ? formatPrice(9900) 
                  : `${formatPrice(99000 / 12)}/mo`}
              </span>
              <span className="period">
                {billingCycle === 'monthly' ? 'per month' : 'billed annually'}
              </span>
            </div>
            <p>Unlimited data for serious communities</p>
          </div>
          <ul className="plan-features">
            <li>Unlimited messages</li>
            <li>Unlimited message history</li>
            <li>Up to 5 Discord servers</li>
            <li>Advanced analytics</li>
            <li>Priority support</li>
            <li>API access</li>
            <li>Custom integrations</li>
          </ul>
          {currentPlan === 'pro' ? (
            <button className="plan-cta current">Current Plan</button>
          ) : (
            <>
              <button 
                className="plan-cta" 
                onClick={() => handleSubscribe(billingCycle === 'monthly' ? proMonthlyPrice : proAnnualPrice)}
              >
                Subscribe
              </button>
              {!currentPlan && (
                <button 
                  className="plan-cta secondary" 
                  onClick={() => handleStartTrial('pro')}
                >
                  Start 14-Day Free Trial
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <div className="plan-note">
        <p>All plans include SSL encryption, Discord integration, and our web dashboard</p>
        <p className="guarantee">14-day money-back guarantee. Cancel anytime.</p>
      </div>
    </div>
  );
};

export default SubscriptionPlans; 